<template>
  <div class="mentions-legales container my-5 py-5">
    <h2 class="mb-4">Mentions légales :</h2>
    <p>
      Le site internet institut-taxi.fr est édité par la société Institut-taxi,
      centre de formation agréé située à Gennevilliers (92230) dans les
      Hauts-de-Seine, au 10 Rue de la sablière, France.
    </p>
    <p>Adresse de contact : contact@instiut-taxi.fr</p>
    <p>
      Le site internet a été réalisé par l'agence web Malaweb, située à Paris,
      France.
    </p>
    <p>Hébergement :</p>
    <p>
      OVH, 2 rue Kellermann - 59100 Roubaix - France, immatriculée au Registre
      du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419
      00045.
    </p>
    <h3 class="mt-5 mb-4">Protection des données personnelles :</h3>
    <p>
      L'institut taxi s'engage à respecter la législation française et
      européenne en matière de protection des données personnelles, notamment le
      Règlement Général sur la Protection des Données (RGPD).
    </p>
    <p>
      Les données personnelles collectées par l'institut Taxi sont celles que
      vous fournissez lors de vos correspondance sur le site institut-taxi.fr,
      demande de renseignement, formulaire de contact, inscription à une
      formation (nom, prénom, adresse e-mail, numéro de téléphone). Ces données
      sont conservées pendant la durée nécessaire à la gestion de votre demande.
      l'institu taxi ne partage vos données personnelles avec aucun partenaire.
    </p>
    <p>
      L'institut taxi utilise Google Analytics pour mesurer le trafic sur son
      site internet, mais ne partage jamais les informations ni ne les utilise à
      des fins publicitaires.
    </p>
    <p>
      Vous disposez d'un droit d'accès, de rectification, de suppression et de
      portabilité de vos données personnelles. Vous pouvez exercer ces droits en
      contactant l'institut taxi à l'adresse de contact indiquée ci-dessus.
    </p>
    <h3 class="mt-5 mb-4">Copyright :</h3>
    <p>
      Copyright © L'institut taxi. Tous droits réservés. Toute reproduction
      totale ou partielle du contenu de ce site, par quelque moyen que ce soit,
      sans autorisation préalable de L'institut taxi est interdite et
      constituerait une contrefaçon sanctionnée par les articles L335-2 et
      suivants du Code de la Propriété Intellectuelle.
    </p>
    <h3 class="mt-5 mb-4">Loi applicable et juridiction compétente :</h3>
    <p>
      Les présentes mentions légales sont régies par la loi française. Tout
      litige relatif à l'interprétation ou à l'exécution des présentes mentions
      légales sera de la compétence exclusive des tribunaux français.
    </p>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  name: "MentionLegale",
  setup() {
    useMeta({
      title: "Mentions Légales",
      description: [
        {
          content:
            "L'institut taxi s'engage à respecter la législation française et européenne en matière de protection des données personnelles, notamment le Règlement Général sur la Protection des Données (RGPD).",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/mentions",
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
h2 {
  color: $color-secondary;
}
</style>
