<template>
  <HeaderYellow
    anteTitle="L'actualité"
    title="Formation vtc & taxi"
    postTitle="Découvrez l'univers du transport de personnes."
    :backgroundImage="require('@/assets/pictures/itti-front.webp')"
  />
  <!-------------------------------TEST----------------------->
  <ArticleSelect :posts="Posts" />
  <!-------------------------------TEST----------------------->
</template>

<script>
import { useMeta } from "vue-meta";
import ArticleSelect from "@/components/blog/ArticleSelect.vue";
import HeaderYellow from "@/components/headers/HeaderYellow.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BlogView",
  components: { ArticleSelect, HeaderYellow },
  setup() {
    useMeta({
      title: "L'actu des formations taxi & vtc",
      description: [
        {
          content:
            "Découvrez les dernières actualités et informations utiles sur la page blog de l'Institut Taxi Centre de Formations VTC et Taxi à Gennevilliers dans le 92. Restez à jour avec les dernières tendances de l'industrie du transport, les réglementations en vigueur et les conseils pratiques pour réussir dans le domaine des VTC et des taxis.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/blog",
        },
      ],
    });
  },
  created() {
    // Appel initial à GetPosts() pour récupérer tous les articles du blog
    this.$store.dispatch("GetPostsPublished");
  },
  computed: {
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
    }),
  },
  methods: {
    ...mapActions(["GetPostsPublished", "GetPost"]),
    async showPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "article",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
