<template>
  <div
    class="social_media d-flex flex-column justify-content-between align-items-center pt-2 mb-4 mb-md-4"
  >
    <div class="follow">
      <h5 class="jaapokki mt-md-3 mt-2 center-text">Suivez nous !</h5>
    </div>
    <div>
      <a
        href="https://www.facebook.com/ITTI.92"
        aria-label="social media link facebook"
        class="footer-icon"
      >
        <i class="fab fa-facebook-f fa-lg white-text mx-2"> </i>
      </a>
      <a
        href="https://www.instagram.com/institut.taxi/"
        aria-label="social media link instagram"
        class="footer-icon"
      >
        <i class="fab fa-instagram fa-lg white-text mx-2"> </i>
      </a>
      <a
        href="https://twitter.com/InstitutTaxi"
        aria-label="social media link twitter"
        class="footer-icon"
      >
        <i class="fab fa-twitter fa-lg white-text mx-2"> </i>
      </a>
    </div>

    <div></div>

    <div>
      <a
        href="https://www.snapchat.com/add/snap.wss%3Cspan%20id=%22ms-outlook-android-cursor%22%3E!~OMSelectionMarkerEnd~"
        aria-label="social media link snapchat"
        class="footer-icon"
      >
        <i class="fab fa-snapchat-ghost white-text fa-lg mx-2"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/institut-taxi"
        aria-label="social media link linkedin"
        class="footer-icon"
      >
        <i class="fab fa-linkedin-in fa-lg white-text mx-2"> </i>
      </a>

      <a
        href="https://www.pinterest.fr/itti92"
        aria-label="social media link pinterest"
        class="footer-icon"
      >
        <i class="fab fa-pinterest fa-lg white-text mx-2"> </i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
};
</script>

<style lang="scss" scoped>
.follow {
  color: $color-secondary;
  & .hr {
    width: 100%;
    height: 2px;
    background: $color-primary;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 5px;
  }
}
.social_media {
  & div {
    margin: 5px;
  }
  & i:not(.fa-facebook-f) {
    width: 37px;
    color: $color-primary;
    background: $color-secondary;
    padding: 9px;
    border-radius: 10px;
    &:hover {
      background: #002a54;
      color: $color-secondary;
    }
  }
  & .fa-facebook-f {
    width: 37px;
    color: $color-primary;
    background: $color-secondary;
    padding: 9px 11px;
    border-radius: 10px;
    &:hover {
      background: #002a54;
      color: $color-secondary;
    }
  }
}
</style>
