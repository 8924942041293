import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import posts from "./modules/posts";
import towns from "./modules/towns";
import comments from "./modules/comments";
import profileUser from "./modules/profileUser";
import blogMedia from "./modules/blogMedia";
import blogCategory from "./modules/blogCategory";
import blogTag from "./modules/blogTag";
import maladmin from "./modules/maladmin";
import mailjs from "./modules/mailjs";
import readMe from "./modules/readMe";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    posts,
    towns,
    comments,
    profileUser,
    blogMedia,
    blogCategory,
    blogTag,
    maladmin,
    mailjs,
    readMe,
  },
  plugins: [createPersistedState()],
});
