<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('formation-vtc')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation VTC pratique seule" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/examen-pratique-vtc.webp"
                          alt="highway-timelaps"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">VTC PRATIQUE SEULE</h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />390€</p>
                            <!-- Twitter -->
                            <p><span>Durée</span><br />3 séances de conduite</p>
                            <!-- Google+ -->
                            <p>
                              <span>Voiture de prêt</span><br />pour l'examen
                            </p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>VTC examen pratique seul</h2>
                      <p>
                        Nous vous proposons une
                        <strong>formation taxi pratique</strong> intensive et
                        ciblée pour vous préparer efficacement à l'examen
                        <strong>T3P</strong> (<strong
                          >Transport Public Particulier de Personnes</strong
                        >). Notre
                        <strong>formation "pratique seule" pour taxi</strong> se
                        concentre spécifiquement sur les compétences nécessaires
                        pour réussir l'<strong
                          >épreuve pratique de l'examen T3P</strong
                        >.
                      </p>
                      <p>
                        Avec notre <strong>formation</strong>, vous bénéficierez
                        de séances de conduite dédiées à la préparation de
                        l'<strong>épreuve pratique</strong>. Nos formateurs
                        expérimentés vous guideront et vous fourniront les
                        conseils nécessaires pour maîtriser les techniques de
                        conduite spécifiques à l'activité de transport de
                        personnes.
                      </p>
                      <p>
                        Nous mettons à votre disposition un véhicule adapté aux
                        exigences de l'<strong>examen T3P</strong>, vous
                        permettant de vous familiariser avec les conditions
                        réelles de conduite lors de l'épreuve pratique. Vous
                        serez entraîné à effectuer les manœuvres requises, à
                        gérer les situations de circulation complexes et à
                        assurer la sécurité des passagers et des autres usagers
                        de la route.
                      </p>
                      <p>
                        Notre objectif est de vous préparer de manière efficace
                        et rigoureuse pour que vous puissiez réussir l'<strong
                          >épreuve pratique de l'examen T3P</strong
                        >. Nous mettons tout en œuvre pour vous aider à
                        développer les compétences nécessaires et à gagner en
                        confiance en tant que conducteur de
                        <strong
                          >transport public particulier de personnes</strong
                        >.
                      </p>
                      <p>
                        En choisissant notre
                        <strong>formation pratique</strong> pour l'<strong
                          >examen T3P</strong
                        >, vous bénéficierez d'une préparation complète, axée
                        sur les exigences spécifiques de l'<strong
                          >épreuve pratique</strong
                        >. Nous vous offrons l'opportunité de vous entraîner et
                        de vous perfectionner dans un environnement encadré,
                        afin d'augmenter vos chances de succès à l'<strong
                          >examen</strong
                        >
                        et d'accéder à la
                        <strong>profession de chauffeur taxi</strong>.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "VtcPratiqueSeule",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: " Examen pratique seule VTC",
      description: [
        {
          content:
            "Préparez-vous à l'examen pratique VTC avec notre formation complète. Évaluez vos compétences de conduite en circulation, la sécurité des passagers et des autres usagers de la route, ainsi que votre capacité à offrir un service client de qualité en tant que chauffeur VTC.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-vtc-pratique-seule",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
