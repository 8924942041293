<template>
  <section class="container itti">
    <div class="itti__header text-center">
      <h2>
        <span class="fall">ITTI</span><br />
        votre école Taxi et VTC
      </h2>
      <p class="text-center">
        Votre partenaire de confiance pour des
        <strong>formations</strong> complètes et professionnelles dans le
        domaine du
        <strong>transport de personnes</strong> (<strong>t3p</strong>).
        Rejoignez-nous et donnez un nouvel élan à votre carrière.
      </p>
      <div class="col-lg-6 itti__header-img">
        <div class="cadre">
          <img
            src="@/assets/pictures/itti-front.webp"
            class="img-fluid"
            alt="front-of-the-taxi-institut"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="itti__row">
      <div class="col-lg-3 col-md-6 item fall">
        <div class="item__box">
          <i class="fa fa-eur" aria-hidden="true"></i>
          <div>
            <h3>Financements</h3>
            <div>
              <p>
                L'Institut Taxi accepte le
                <strong>Compte Personnel de Formation</strong>
                <strong>(CPF)</strong> pour ses <strong>formations</strong> et
                propose également un paiement en 3 fois sans frais.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 item fall">
        <div class="item__box">
          <i class="fa fa-wheelchair" aria-hidden="true"></i>
          <div>
            <h3>PMR</h3>
            <div class="blue">
              <p>
                Nos infrastructures adaptées, garantissent un accès facile et
                confortable à nos locaux de
                <strong>formation aux personnes à mobilité réduite</strong>
                (<strong>PMR</strong>).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 item fall">
        <div class="item__box">
          <i class="fa fa-pen-alt"></i>
          <div>
            <h3>Cours</h3>
            <div>
              <p>
                L'Institut Taxi propose des
                <strong>formations</strong> flexibles adaptées à différents
                horaires, y compris des <strong>cours du soir</strong> et des
                <strong>formations accélérées</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="hr-d"></div>
</template>

<script>
export default {
  name: "IttiHome",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.itti {
  padding: 50px 10px 50px 10px;
  @media screen and (min-width: 992px) {
    padding: 100px 0px 10px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & h2 {
      color: $color-text;
      margin-bottom: 25px;
      line-height: 2.3rem;
      @media screen and (min-width: 992px) {
        margin-bottom: 25px;
        line-height: 2.3rem;
      }
      & span {
        font-family: "cunia";
        color: $color-secondary;
        font-size: 3em;
      }
    }
  }
  &__header-img {
    padding: 40px 0px 60px;
    & .cadre {
      padding: 3px;
      background-color: $color-primary;
      border: 0.3px $color-secondary outset;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    & .item {
      margin: 20px;
      & h3 {
        color: $color-primary;
      }
      &__box {
        & i {
          color: $color-secondary;
          font-size: 3em;
          margin-bottom: 5px;
        }
        & .fa-wheelchair {
          color: $color-primary !important;
        }
        & div > div:nth-child(n + 1) {
          border: 0.2px $color-secondary outset;
          border-radius: 10px 2px 10px 2px;
          padding: 5px 10px;
          background-color: $color-secondary;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $color-text-light;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
          @media screen and (min-width: 992px) {
            width: 100%;
            height: 240px;
            padding: 5px 10px;
          }
        }
      }
    }
  }
}
.blue {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}
.fall.active {
  @include fall-down(1s);
}
</style>
