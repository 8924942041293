<template>
  <div class="container">
    <section class="footer-icons">
      <div class="footer-icons__group">
        <a href="tel:+33787185918">
          <p class="blue">APPEL</p>
          <div class="allIcons">
            <img
              src="@/assets/icons/icons-appel.webp"
              alt="icon-phone"
              loading="lazy"
            />
          </div>
        </a>
      </div>
      <div class="footer-icons__group mx-2">
        <div class="allIcons">
          <div class="pipe"></div>
        </div>
      </div>
      <div class="footer-icons__group">
        <a href="sms:+33787185918">
          <p class="yellow">SMS</p>
          <div class="allIcons">
            <img src="@/assets/icons/icons-sms.webp" alt="icon-sms" />
          </div>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "BtnCallNav",
};
</script>

<style lang="scss" scoped>
.footer-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
  &__group {
    text-align: center;
    a {
      transition: 0.5s;
      & :hover {
        scale: 94%;
      }
      & .yellow {
        color: $color-secondary;
      }
      & .blue {
        color: $color-primary;
      }
    }
    & img {
      width: 35px;
    }
    & p {
      font-size: 0.7em;
      text-align: center;
      margin-bottom: 0px;
      font-family: "adam";
    }
    & .allIcons {
      width: fit-content;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      & .pipe {
        width: 1.5px;
        height: 40px;
        @include black-gradient;
      }
    }
  }
}
</style>
