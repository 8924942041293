<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('formation-taxi')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation Taxi Continue" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/formation-taxi-continue.webp"
                          alt="time-laps-car-turn"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">TAXI CONTINUE</h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />229€</p>
                            <!-- Twitter -->
                            <p><span>Durée</span><br />14h / 2 jours</p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Formation taxi continue</h2>
                      <p>
                        L'Institut Taxi propose la
                        <strong
                          >formation continue obligatoire pour les chauffeurs de
                          taxi</strong
                        >
                        titulaires d'une <strong>carte professionnelle</strong>.
                        Cette <strong>formation</strong>, d'une durée de 14
                        heures, permet aux chauffeurs de se maintenir à jour
                        avec la réglementation en vigueur. Elle se compose de
                        quatre modules : le
                        <strong
                          >droit du transport public particulier de
                          personnes</strong
                        >, les réglementations spécifiques à l'activité de taxi,
                        la sécurité routière et la gestion. Grâce à cette
                        <strong>formation continue</strong>, les
                        <strong>chauffeurs de taxi</strong> peuvent exercer leur
                        métier avec compétence et professionnalisme.
                      </p>
                      <div class="desk-card">
                        <div>
                          <h2>Renouvellement carte professionnelle taxi</h2>
                          <div class="card">
                            <div class="card-body">
                              <h3 class="card-subtitle card-title mb-2">
                                4 MODULES
                              </h3>

                              <ul class="list-group">
                                <li class="list-group-item">
                                  A – Droit du transport public particuliers de
                                  personnes
                                </li>
                                <li class="list-group-item">
                                  B – Réglementation spécifique lié à l’activité
                                  de taxi
                                </li>
                                <li class="list-group-item">
                                  C – Sécurité Routière
                                </li>
                                <li class="list-group-item">D – Gestion</li>
                              </ul>
                              <div class="card-footer text-muted">
                                <p class="card-text mt-3">3h30 par module</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2>
                        Faire une demande de renouvellement de la carte
                        professionnelle de taxi
                      </h2>
                      <p>
                        Pour
                        <strong
                          >renouveler sa carte professionnelle de taxi</strong
                        >, il est nécessaire de suivre une
                        <strong>formation continue</strong>. Cette
                        <strong>formation</strong> a une durée de 14 heures et
                        doit être effectuée tous les cinq ans. Elle vise à
                        mettre à jour les connaissances des chauffeurs sur des
                        sujets tels que le droit du
                        <strong
                          >transport public particulier de personnes</strong
                        >, les réglementations spécifiques à l'<strong
                          >activité de taxi</strong
                        >, la sécurité routière et la gestion.
                      </p>

                      <p>
                        Pour cela, les chauffeurs doivent s'inscrire auprès d'un
                        <strong>centre de formation agréé</strong>, comme
                        l'Institut Taxi. Pendant la <strong>formation</strong>,
                        ils participent activement aux sessions animées par des
                        formateurs qualifiés. À la fin de la
                        <strong>formation</strong>, une évaluation ou un test
                        est réalisé pour valider les acquis des chauffeurs.
                      </p>

                      <p>
                        Une fois la <strong>formation</strong> réussie, les
                        chauffeurs peuvent entamer les démarches de
                        renouvellement de leur
                        <strong>carte professionnelle de taxi</strong>. Ils
                        doivent fournir les documents requis, tels que le
                        certificat de formation, aux autorités compétentes
                        chargées du renouvellement des cartes.
                      </p>

                      <p>
                        Le
                        <strong
                          >renouvellement de sa carte professionnelle de
                          taxi</strong
                        >
                        est essentiel pour rester à jour sur les réglementations
                        en vigueur et assurer un service de qualité aux
                        passagers. C'est une étape importante pour les
                        chauffeurs afin de maintenir leurs compétences et
                        continuer à exercer leur profession de manière
                        responsable et professionnelle.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "TaxiContinue",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "Formation continue taxi",
      description: [
        {
          content:
            "Suivez notre formation continue Taxi pour rester à jour sur la réglementation en vigueur, les nouvelles technologies, les bonnes pratiques de service client et bien plus encore. Améliorez vos compétences en tant que chauffeur de taxi expérimenté.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-taxi-continue",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
