<template>
  <div class="container mt-lg-5 py-5">
    <!--Section: Content-->
    <section class="dark-grey-text mb-5 contact">
      <!-- Section heading -->
      <h2 class="font-weight-bold text-center mt-2 mb-4">
        Contactez votre centre de formations vtc et taxi
      </h2>
      <!-- Section description -->
      <p class="text-center w-responsive mx-auto pb-5">
        Vous souhaitez <strong>devenir chauffeur de taxi</strong>, obtenir la
        <strong>carte professionnelle vtc</strong>, effectué un
        <strong>stage de recupération de points</strong> ou simplement suivre
        une <strong>formation continue</strong> en tant que chauffeur de
        <strong>taxi</strong> ou <strong>vtc</strong> ?<br />N'attendez plus,
        contactez-nous !
      </p>

      <!-- Grid row -->
      <div class="row contact__form">
        <!-- Grid column -->
        <div class="left-form col-lg-6 mb-lg-0 pb-lg-3 slide-left">
          <!-- Form with header -->
          <div class="card">
            <div class="card-body">
              <!-- Header -->
              <div class="form-header blue accent-1">
                <h3 class="mt-2">
                  <i class="fas fa-envelope"></i> Écrivez-nous :
                </h3>
              </div>
              <p class="dark-grey-text">
                on répondra à vos questions, et c'est gratuit !
              </p>
              <!-- Body -->
              <form ref="formData" @submit.prevent="sendEmail">
                <div class="md-form">
                  <i class="fas fa-user prefix grey-text"></i>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="form-control"
                    maxlength="30"
                    required
                  />
                  <label for="name">Nom</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-envelope prefix grey-text"></i>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    class="form-control"
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  />
                  <label for="email">Email</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-phone prefix grey-text"></i>
                  <input
                    type="tel"
                    placeholder="Téléphone"
                    name="phone"
                    id="phone"
                    class="form-control"
                    required
                    pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
                  />
                  <label for="email">Phone</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-tag prefix grey-text"></i>
                  <input
                    type="text"
                    name="reason"
                    id="reason"
                    class="form-control"
                    pattern="^[^/<>\n]*$"
                    required
                  />
                  <label for="phone">Sujet</label>
                </div>
                <div class="md-form">
                  <i class="fas fa-pencil-alt prefix grey-text"></i>
                  <textarea
                    id="message"
                    name="message"
                    class="form-control md-textarea"
                    rows="3"
                    pattern="^[^/<>\n]*$"
                    maxlength="450"
                    required
                  ></textarea>
                  <label for="message">Message</label>
                </div>
                <div class="text-center">
                  <span v-if="this.isCaptchaVisible">
                    <vue-hcaptcha
                      sitekey="223d82ce-7279-4269-99d2-ba9276578c24"
                      @verify="onVerify"
                      @error="onError"
                    ></vue-hcaptcha>
                  </span>
                  <button name="contactBtn" class="btn btn-light-blue">
                    Envoyer
                  </button>
                </div>
              </form>
              <span v-if="this.modalConfirm">
                <ConfirmModal
                  @closeModalConfirm="closeModalConfirm"
                  title="Merci de votre intérêt"
                  message="Vous allez être recontactés dans les plus brefs délais"
                  btnExit="À bientôt"
                />
              </span>
            </div>
          </div>
          <!-- Form with header -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="right-form col-lg-6 mb-lg-0 pb-lg-3">
          <!-- Form with header -->
          <div class="card slide-right">
            <div class="card-body">
              <!-- Header -->
              <div class="form-header blue accent-1">
                <h3 class="mt-2">
                  <i class="fas fa-envelope"></i> Une question ?
                </h3>
              </div>
              <p class="dark-grey-text">Venez nous rencontrés !</p>
              <!-- Body -->
              <div id="calendar" class="md-form">
                <CalendlyVue />
              </div>
            </div>
          </div>
          <!-- Form with header -->
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <!-- Grid column -->
      <div class="col-lg-12">
        <h3 class="font-weight-bold text-center mt-4 mb-4">
          Votre centre de formations taxi et vtc<br />
          Gennevilliers, Hauts-de-Seine (92)
        </h3>
        <!--Google map-->
        <div
          id="map-container-section"
          class="z-depth-1-half map-container-section my-4"
          style="height: 400px"
        >
          <div style="width: 100%">
            <iframe
              loading="lazy"
              title="myFrame"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=10%20Rue%20de%20la%20Sabli%C3%A8re,%2092230%20Gennevilliers+(l'Institut%20Taxi)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="400"
              frameborder="0"
            >
              ></iframe
            >
          </div>
        </div>
        <!-- Buttons-->
        <h3 class="follow text-center mt-2 mb-4">
          L'Institut <span>Taxi</span>
        </h3>
        <div class="contact__info fall">
          <ContactCard />
          <SocialMedia />
        </div>
      </div>
      <!-- Grid column -->
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import ContactCard from "@/components/partials/ContactCard.vue";
import SocialMedia from "@/components/partials/SocialMedia.vue";
import CalendlyVue from "@/components/plugins/CalendlyVue.vue";
export default {
  name: "contactForm",
  components: {
    ContactCard,
    SocialMedia,
    VueHcaptcha,
    ConfirmModal,
    CalendlyVue,
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", ".fall");
    });
  },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendContactEmail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendContactEmail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &__form {
    position: relative;
    & .left-form {
      margin-bottom: 10px;
      & .card {
        background: $color-primary;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
        & h3 {
          color: $color-text-light;
        }
        & p {
          color: $color-text-light;
        }
        & .md-form {
          margin-top: 20px;
        }
      }
      & i {
        color: #fff;
      }
      & label {
        color: $color-secondary;
      }
      & button {
        border: 1px outset $color-secondary;
        color: $color-text-light;
        margin: 70px 0px 20px;
        &:hover {
          border: 1px inset $color-text-light;
          background: $color-secondary;
          color: $color-primary;
        }
      }
    }
    & .right-form {
      & .card {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
        height: 100%;
        background: $color-secondary;
        margin-bottom: 10px;
        & h3 {
          color: $color-text;
        }
        & p {
          color: $color-text;
        }
        & .md-form {
          margin-top: 20px;
        }
      }
      & i {
        color: #fff;
      }
      & label {
        color: $color-darker;
      }
      & button {
        border: 1px outset $color-text-light;
        color: $color-text;
        margin: 70px 0px 20px;
        &:hover {
          border: 1px inset $color-text;
          background: $color-primary;
          color: $color-secondary;
        }
      }
    }
  }
  & .follow {
    color: $color-primary;
    padding-top: 50px;
    & span {
      color: $color-secondary;
    }
  }
  &__info {
    background: #feb914;
    background: linear-gradient(to right, #feb914 50%, #002a54 50%);
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #feb914;
      background: linear-gradient(to bottom, #feb914 56%, #002a54 50%);
    }
  }
}
.map-container-section {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-section iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.g-recaptcha {
  margin-bottom: 30px;
}
textarea {
  font-family: "Arial Black";
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
.fall.active {
  @include fall-down(1s);
}
</style>
