<template>
  <header :style="{ backgroundImage: `url('${backgroundImage}')` }">
    <div class="item">
      <div class="container">
        <div class="header-content">
          <div class="line"></div>
          <p>{{ anteTitle }}</p>
          <h1>{{ title }}</h1>
          <p>{{ postTitle }}</p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  components: {},
  name: "HeaderYellow",
  props: {
    anteTitle: String,
    title: String,
    postTitle: String,
    backgroundImage: String,
  },
  computed: {
    hasBackgroundImage() {
      return this.backgroundImage !== undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding-top: 30px;
  @media screen and (min-width: 992px) {
    padding-top: 80px;
  }
  @include background-responsive;
  & .item {
    width: 100vw;
    height: fit-content;
    min-height: 50vh;
    padding: 75px 0;
    background: #faf6086c;
  }
}
.header-content {
  position: relative;
  overflow: hidden;
  padding: 30px 30px 0px 40px;
  @media screen and (min-width: 992px) {
    padding: 56px;
  }
  & .line {
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border: 9px solid $color-primary;
    -webkit-clip-path: polygon(100% 0, 40% 0, 64% 100%, 100% 100%);
    clip-path: polygon(100% 0, 40% 0, 64% 100%, 100% 100%);
  }
  & p:first-of-type {
    font-family: "adam";
    color: #fff;
    font-size: 1.1em;
    @media screen and (min-width: 992px) {
      font-size: 1.6em;
    }
  }
  & h1 {
    font-family: "cunia";
    font-size: 1.5em;
    margin: 5px 0 20px;
    word-spacing: 3px;
    color: #fff;
    @media screen and (min-width: 992px) {
      font-size: 2.5em;
    }
  }
  & p:last-of-type {
    font-family: "adam";
    font-size: 1.1em;
    line-height: 1.4em;
    color: #fff;
    @media screen and (min-width: 992px) {
      font-size: 1.4em;
    }
  }
}

//clip-path: polygon(100% 0, 40% 0, 64% 100%, 100% 100%);
</style>
