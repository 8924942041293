<template>
  <section class="container-fluid double-block">
    <div class="double-block__title">
      <h2>Votre centre de formation agréé pour <br />les taxi et vtc.</h2>
    </div>
    <div class="dark-grey-text double-block__articles">
      <div class="row text-center align-items-center article">
        <div class="col-lg-5">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <img
              src="@/assets/pictures/room-out.webp"
              alt="the-room-of-vtc-formation"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 pd slide-right">
          <h3 class="mb-3">Formations VTC</h3>
          <p>
            L'<strong>Institut Taxi</strong> propose une variété de
            <strong>formations pour les conducteurs VTC en Île-de-France</strong
            >.
          </p>

          <p>
            La <strong class="formation-name">formation initiale</strong> permet
            d'obtenir la précieuse <strong>carte professionnelle VTC</strong>,
            indispensable pour exercer cette activité.
          </p>
          <p>
            La <strong class="formation-name">formation continue</strong> qui
            est obligatoire et prévue tous les 5 ans pour vous permettre de
            renouveler votre <strong>carte professionnelle VTC</strong>.
          </p>
          <p>
            La
            <strong class="formation-name"
              >formation passerelle de VTC vers taxi</strong
            >, pour ceux qui souhaitent effectuer une transition vers le
            <strong>métier de chauffeur de taxi</strong>.
          </p>
          <p>
            Ces formations complètes et adaptées offrent aux candidats toutes
            les compétences nécessaires pour réussir dans le domaine du
            <strong>transport public particulier de personnes</strong>
            (<strong>T3P</strong>).
          </p>

          <div @click="circleBtn('formation-vtc')" class="div-btn">
            <a href="#" v-on:click.prevent class="btn btn__first"
              >En savoir plus &raquo;</a
            >
          </div>
        </div>
      </div>
      <hr class="my-5" />
      <div class="row text-center align-items-center article__left">
        <div class="col-lg-5 order-lg-2 slide-right">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/room2.webp"
              alt="classroom-taxi"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 pd order-lg-1 slide-left">
          <h3 class="mb-3">Formations Taxi</h3>
          <p>
            L'<strong>Institut Taxi</strong> offre une gamme de formations
            complètes pour les
            <strong>conducteurs de taxi en Île-de-France</strong>.
          </p>
          <p>
            La
            <strong class="formation-name-t">formation initiale</strong> prépare
            les candidats à obtenir leur
            <strong>carte professionnelle de taxi</strong>, indispensable pour
            exercer légalement cette profession.
          </p>
          <p>
            La
            <strong class="formation-name-t">formation continue</strong>
            proposée pour les <strong>conducteurs de taxi</strong>, conformément
            aux exigences légales, afin de mettre à jour leurs compétences et de
            rester au fait des nouvelles réglementations.
          </p>
          <p>
            Les <strong class="formation-name-t">formations mobilité</strong>
            pour élargir votre zone d'activité. Les
            <strong>conducteurs de taxi</strong> peuvent suivre des formations
            spécifiques pour ajouter les départements <strong>92</strong> et
            <strong>75</strong> à leur
            <strong>carte professionnelle taxi</strong>.
          </p>
          <p>
            La
            <strong class="formation-name-t">formation passerelle</strong>
            permettant aux conducteurs taxi de <strong>devenir vtc</strong>.
          </p>
          <p>
            Ces formations spécialisées offrent aux candidats tous les outils
            nécessaires pour réussir dans le domaine du
            <strong>transport public particulier de personnes</strong>
            (<strong>T3P</strong>) en tant que
            <strong>chauffeur de taxi professionnel</strong>.
          </p>

          <div
            @click="circleBtn('formation-taxi')"
            class="div-btn div-btn__rightSide"
          >
            <a href="#" v-on:click.prevent class="btn btn__second"
              >En savoir plus &raquo;</a
            >
          </div>
        </div>
      </div>
      <hr class="my-5" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeDoubleSection",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left");
    });
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.double-block {
  @media screen and (min-width: 992px) {
    padding: 20px 25px;
  }
  & img {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0.5px 0.5px;
  }
  &__title {
    text-align: center;
    margin: 70px 5px 50px;
    & h2 {
      color: $color-primary;
    }
    @media screen and (min-width: 992px) {
      margin: 100px 0px;
    }
  }
  &__articles {
    margin: 0px 5px;
    .article {
      & h3 {
        color: $color-secondary;
      }
      &__left {
        & h3 {
          color: $color-primary;
        }
      }
    }
    & .div-btn {
      transition: 0.3s;
      display: flex;
      justify-content: flex-start;
      &__rightSide {
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 992px) {
          display: flex;
          justify-content: flex-end;
        }
      }
      & .btn {
        padding: 7px 10px;
        font-size: 0.8em;
        border: 0.3px outset;
        transition: 0.3s;
        &__first {
          background-color: $color-primary;
          color: $color-secondary;
        }
        &__second {
          background-color: $color-secondary;
          color: $color-primary;
        }
      }
      & :hover {
        scale: 96%;
      }
    }
  }
}
.pd {
  padding: 0px 20px;
  @media screen and (min-width: 992px) {
    padding: 40px;
  }
}
.formation-name {
  color: $color-primary;
}
.formation-name-t {
  color: $color-secondary;
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
</style>
