<template>
  <span v-if="showButton">
    <div class="row">
      <a
        class="button"
        @click.prevent="showCallForm"
        id="myButton"
        href="#"
        style="--color: #1e9bff"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        s'inscrire à la prochaine session
      </a>
    </div>
  </span>
  <span v-if="OrderLesson">
    <section class="col-10 col-lg-5 container formulaire">
      <span class="close-cross" @click="closeOrder">X</span>
      <div class="formulaire__header">
        <h4>{{ lesson }}</h4>
        <p>
          Notre équipe vous recontact dans les plus brefs délais pour répondre à
          vos questions et établir les prochaines étapes de votre inscription.
        </p>
        <p>
          L'institut taxi se charge de toute les démarches administratives, vous
          transmettez les documents nécéssaire à votre inscription par mail ou
          venez à notre rencontre à l'institut pour déposé les pièces à
          fournirs.
        </p>
      </div>
      <form ref="formData" @submit.prevent="sendEmail">
        <div class="form">
          <div class="col">
            <input
              type="hidden"
              name="reason"
              value="Inscription à une formation"
              required
            />
          </div>
          <div class="col">
            <input type="hidden" name="order" :value="lesson" required />
          </div>
          <div class="col">
            <input
              type="text"
              placeholder="Votre Nom"
              name="name"
              maxlength="30"
              required
            />
          </div>
          <div class="col">
            <input
              type="tel"
              placeholder="Votre Téléphone"
              name="phone"
              required
              pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
            />
          </div>
          <span v-if="this.isCaptchaVisible">
            <vue-hcaptcha
              sitekey="223d82ce-7279-4269-99d2-ba9276578c24"
              @verify="onVerify"
              @error="onError"
            ></vue-hcaptcha>
          </span>
          <button name="button" type="submit">Envoyer</button>
        </div>
      </form>
      <span v-if="this.modalConfirm">
        <ConfirmModal
          @closeModalConfirm="closeModalConfirm"
          title="Merci de votre intérêt"
          message="Vous allez être recontactés dans les plus brefs délais"
          btnExit="À bientôt"
        />
      </span>
    </section>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: { VueHcaptcha, ConfirmModal },
  props: {
    lesson: String,
  },
  data() {
    return {
      OrderLesson: false,
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
      showButton: false,
      scrollHeight: 300, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendCallMeMail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendCallMeMail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
      this.OrderLesson = false;
    },
    showCallForm() {
      this.OrderLesson = true;
      this.showButton = false;
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight && this.OrderLesson != true) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
    closeOrder() {
      this.OrderLesson = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 100px 0px 130px 0px;
}
@media screen and (max-width: 992px) {
  .content {
    margin: 80px 0;
  }
  .trait {
    margin: 60px 20vw;
    width: 40vw;
    height: 1px;
    background-color: $color-primary;
  }
}
.image {
  width: 100%;
  margin-top: 80px;
  img {
    width: 100%;
    margin-bottom: 50px;
  }
}
h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 1.8em;
  text-shadow: 1px 1px 2px #000000aa;
  span {
    color: black;
  }
}
h3 {
  color: black;
  font-size: 1.1em;
  margin-bottom: 20px;
  font-weight: bold;
}
p {
  margin-bottom: 10px;
}
img {
  width: 20px;
  margin-right: 10px;
}
.formulaire {
  position: fixed;
  top: 58%;
  left: 50%;
  width: 80%;
  max-width: 800px;
  transform: translate(-50%, -50%);
  z-index: 500;
  padding: 10px 20px 20px;
  background-color: black;
  border: 0.5px outset $color-secondary;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  .close-cross {
    position: fixed;
    top: -28px;
    left: -18px;
    display: inline;
    color: $color-secondary;
    @include black-gradient;
    padding: 5px 6px;
    border-radius: 50%;
    font-size: 1em;
    cursor: pointer;
    box-sizing: content-box;
    transition: 0.4s;
    &:hover {
      border: 0.1px outset rgba(255, 0, 0, 0.685);
      color: rgba(255, 0, 0, 0.685);
    }
  }
  &__header {
    margin: 10px 0px 20px;
    & h4 {
      color: $color-secondary;
      margin-bottom: 30px;
    }
    & p {
      color: $color-text-light;
      font-size: 0.9em;
    }
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
  }
}
.form {
  background-color: $color-secondary;
  padding: 40px 20px;
  border-radius: 5px;
  text-align: center;
  input {
    margin-bottom: 20px;
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    &:focus {
      box-shadow: 1px 1px 3px black;
    }
    &::placeholder {
      color: $color-primary;
    }
  }
  button {
    border: none;
    padding: 15px 50px;
    border-radius: 100px;
    font-size: 1.2em;
    letter-spacing: 1px;
    background-color: $color-primary;
    color: white;
    opacity: 0.8;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.884);
    &:hover {
      opacity: 1;
    }
    &:active {
      transform: scale(0.98);
      box-shadow: 1px 1px 3px black;
    }
  }
}
.g-recaptcha {
  margin-bottom: 30px;
}

.button {
  position: fixed;
  top: 5rem;
  left: 1.7rem;
  margin: auto;
  z-index: 100;
  width: fit-content;
  padding: 2px 5px;
  font-size: 0.7rem;
  color: $color-secondary;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  text-shadow: 0 0 15px var(--color);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: 0.5s;
  background-color: black;
  @media screen and (min-width: 992px) {
    position: fixed;
    top: 9rem;
    left: 5rem;
    padding: 2px 5px;
    font-size: 0.7rem;
    letter-spacing: 0.1rem;
  }
}
@media screen and (min-width: 992px) {
  .button:hover {
    color: #fff;
    border: 2px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 0px $color-secondary;
  }

  .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-secondary;
    z-index: -1;
    transform: scale(0);
    transition: 0.5s;
  }

  .button:hover::before {
    transform: scale(1);
    transition-delay: 0.5s;
    box-shadow: 0 0 10px $color-secondary, 0 0 30px $color-secondary,
      0 0 60px var(--color);
  }

  .button span {
    position: absolute;
    background: $color-secondary;
    pointer-events: none;
    border-radius: 2px;
    box-shadow: 0 0 10px $color-secondary, 0 0 20px $color-secondary,
      0 0 30px $color-secondary, 0 0 50px var(--color),
      0 0 100px $color-secondary;
    transition: 0.5s ease-in-out;
    transition-delay: 0.25s;
  }

  .button:hover span {
    opacity: 0;
    transition-delay: 0s;
  }

  .button span:nth-child(1),
  .button span:nth-child(3) {
    width: 40px;
    height: 4px;
  }

  .button:hover span:nth-child(1),
  .button:hover span:nth-child(3) {
    transform: translateX(0);
  }

  .button span:nth-child(2),
  .button span:nth-child(4) {
    width: 4px;
    height: 30px;
  }

  .button:hover span:nth-child(1),
  .button:hover span:nth-child(3) {
    transform: translateY(0);
  }

  .button span:nth-child(1) {
    top: calc(50% - 2px);
    left: -50px;
    transform-origin: left;
  }

  .button:hover span:nth-child(1) {
    left: 50%;
  }

  .button span:nth-child(3) {
    top: calc(50% - 2px);
    right: -50px;
    transform-origin: right;
  }

  .button:hover span:nth-child(3) {
    right: 50%;
  }

  .button span:nth-child(2) {
    left: calc(50% - 2px);
    top: -50px;
    transform-origin: top;
  }

  .button:hover span:nth-child(2) {
    top: 50%;
  }

  .button span:nth-child(4) {
    left: calc(50% - 2px);
    bottom: -50px;
    transform-origin: bottom;
  }

  .button:hover span:nth-child(4) {
    bottom: 50%;
  }
}
/*
C3Bpq2JjyjfW3H9Uq8vA3snzxAGrzENz0HMOX8mN0Cc
78CHXlHfVvT_0UZ3qxrhLof-otzSisaGB0xN9K36S64
_P8--nnWnewx-1aXwODGUeSYxE2Zefag23LlYOyKUqM
eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg0NDY0ODI2LCJqdGkiOiI5MmU4NGM2NC00OGY3LTRiMjQtOWViNy03NTkwYmIxN2E4MGYiLCJ1c2VyX3V1aWQiOiJjZTI1ZTU3NC1iY2IwLTQ3NTctOTg2Ny00Nzg0ZTM4NDg5ZTUifQ.Q1CiIlH5sLH2gDH7Q1bGbZBJbhZ91-V-wjV4JI-22kaCKbBriJcB6314vlh8qeeYurb6hG4CRSEmdxmJdRRVCg
https://calendly.com/institut-taxi-fr/formations-taxi-vtc
*/
</style>
