<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('formation-vtc')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation VTC Continue" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/formation-vtc-continue.webp"
                          alt="road-with-circle-speed-effect"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">VTC CONTINUE</h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />170€</p>
                            <!-- Twitter -->
                            <p><span>Durée</span><br />14h / 2 jours</p>
                            <!-- Google+ -->
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Formation VTC continue</h2>
                      <p>
                        La
                        <strong
                          >formation continue pour les chauffeurs VTC</strong
                        >
                        (Véhicules de Tourisme avec Chauffeur) est une étape
                        cruciale pour maintenir ses compétences et rester à jour
                        dans le domaine du transport de personnes. Elle est
                        obligatoire pour tous les chauffeurs
                        <strong
                          >VTC titulaires d'une carte professionnelle</strong
                        >. La durée de la formation, fixée par la
                        réglementation, est de 14 heures renouvelables tous les
                        cinq ans.
                      </p>
                      <p>
                        Cette <strong>formation continue</strong> offre de
                        nombreux avantages. Elle permet aux
                        <strong>chauffeurs VTC</strong> de se tenir informés des
                        dernières réglementations et des évolutions dans le
                        secteur. En suivant régulièrement des
                        <strong>formations</strong>, les chauffeurs peuvent
                        actualiser leurs connaissances en matière de
                        réglementation spécifique aux <strong>VTC</strong>, de
                        sécurité routière, de gestion, ainsi que d'autres
                        aspects importants de leur activité.
                      </p>
                      <div class="desk-card">
                        <div>
                          <h2>Renouvellement carte professionnelle VTC</h2>
                          <div class="card">
                            <div class="card-body">
                              <h3 class="card-subtitle card-title mb-2">
                                4 MODULES
                              </h3>

                              <ul class="list-group">
                                <li class="list-group-item">
                                  A – Droit du transport public particuliers de
                                  personnes
                                </li>
                                <li class="list-group-item">
                                  B – Réglementation spécifique lié à l’activité
                                  de VTC
                                </li>
                                <li class="list-group-item">
                                  C – Sécurité Routière
                                </li>
                                <li class="list-group-item">D – Anglais</li>
                              </ul>
                              <div class="card-footer text-muted">
                                <p class="card-text mt-3">3h30 par module</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2>
                        Faire une demande de renouvellement de sa carte
                        professionnelle de vtc
                      </h2>
                      <p>
                        Pour
                        <strong
                          >renouveler leur carte professionnelle vtc</strong
                        >, les chauffeurs doivent fournir une attestation de
                        suivi de la <strong>formation continue</strong>. Cette
                        attestation est délivrée par un organisme de
                        <strong>formation agréé</strong>. Il est recommandé aux
                        chauffeurs de planifier leur formation à l'avance et de
                        s'inscrire auprès d'un
                        <strong>centre de formation agréé</strong> pour garantir
                        la validité de leur
                        <strong>carte professionnelle vtc</strong>.
                      </p>
                      <p>
                        <strong
                          >Renouveler sa carte professionnelle de chauffeur
                          VTC</strong
                        >
                        est essentiel pour pouvoir continuer à exercer
                        légalement l'activité de transport de personnes. Cela
                        permet de garantir que les
                        <strong>chauffeurs VTC</strong> sont formés aux normes
                        et aux exigences en vigueur, assurant ainsi la sécurité
                        et la satisfaction des clients. De plus, en maintenant
                        leurs compétences à jour, les
                        <strong>chauffeurs VTC</strong> peuvent offrir un
                        service de qualité, adapté aux besoins et aux attentes
                        des passagers.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "VtcContinue",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "Formation continue VTC",
      description: [
        {
          content:
            "Suivez notre formation continue VTC pour rester à jour sur la réglementation en vigueur, les nouvelles tendances du secteur, les techniques de service client avancées et bien plus encore. Améliorez vos compétences en tant que chauffeur VTC expérimenté.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-vtc-continue",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
