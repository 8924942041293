<template>
  <div class="container mt-5">
    <!--Section: Content-->
    <section class="formation">
      <!-- Section heading -->
      <h2 class="text-center font-weight-bold">Formations Taxi</h2>
      <!-- Section description -->
      <div class="mx-auto w-responsive mb-5">
        <div class="container mb-5">
          <!--Section: Content-->
          <section class="text-center">
            <!-- Section heading -->
            <p
              class="text-center argument font-weight-bold dark-grey-text pb-2 my-4"
            >
              <strong>Devenir Taxi</strong>
            </p>
            <!-- Section description -->
            <p class="text-center w-responsive mx-auto mb-5">
              Explorez nos <strong>formations taxi</strong> de qualité,
              élaborées pour vous permettre d'acquérir les compétences
              essentielles et les connaissances approfondies nécessaires à une
              carrière réussie.
            </p>

            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+233€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>90H / 3 semaines</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                        <p>9h30-12h30<br />13h30-16h30</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-taxi-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE-soir</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+233€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>60H / 4 semaines</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                        <p>18h00<br />21h00</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-taxi-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE-accélérée</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+233€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>15H / 1 semaines</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                        <p>18h00<br />21h00</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-taxi-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Grid row -->
            <div class="row justify-content-center">
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="peach-gradient rounded-top">
                    <h3 class="option">CONTINUE</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body striped orange-striped card-background">
                    <p class="price">229<span>€</span></p>
                    <ul>
                      <li>
                        <p>14h / 2 jours</p>
                      </li>
                      <li>
                        <p>Disponibilté<br />à convenir</p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-taxi-continue">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>
                <!-- Pricing card -->
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="purple-gradient rounded-top">
                    <h3 class="option">PRATIQUE SEULE</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body striped purple-striped card-background">
                    <p class="price">390<span>€</span></p>
                    <ul>
                      <li>
                        <p>3 séances de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-taxi-pratique-seule">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>
                <!-- Pricing card -->
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </section>
          <!--Section: Content-->
        </div>
      </div>
      <div class="hr-d"></div>
      <CallToContact
        title="Contactez nous"
        subTitle="L'equipe pédagogique de l'institut taxi s'occupe de vos démarches administratives."
      />
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>
  <section class="container-fluid double-block">
    <div class="double-block__title">
      <h2><span class="brand-pseudo">ITTI</span><br />Ecole Taxi</h2>
    </div>
    <div class="dark-grey-text px-lg-5 double-block__articles">
      <div class="row align-items-center article">
        <div class="col-lg-5 slide-left">
          <div class="view overlay text-center rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/taxi-paris.webp"
              alt="taxi-car-in-paris"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 slide-right">
          <h4 class="mb-3">
            Formations Taxi :<br />
            Développez vos compétences pour une carrière passionnante
          </h4>
          <p>
            Notre <strong>Institut Taxi</strong> propose des
            <strong>formations</strong> complètes et professionnelles pour vous
            permettre de <strong>devenir un chauffeur de taxi</strong> qualifié
            et compétent. Avec notre programme de
            <strong>formation initiale</strong>, vous acquérez les connaissances
            nécessaires sur la réglementation, la sécurité routière, la gestion
            des courses, et bien plus encore. Vous apprendrez également les
            compétences essentielles en matière de service à la clientèle et de
            relations interpersonnelles afin de fournir un service de qualité
            aux passagers. Nos formateurs expérimentés vous guideront tout au
            long de votre parcours d'apprentissage pour vous aider à réussir l'
            <strong>examen pratique et théorique</strong>. De plus, nos
            <strong>formations sont éligibles au CPF</strong>, vous permettant
            de les suivre gratuitement et de booster votre carrière dans le
            domaine du taxi.
          </p>
        </div>
      </div>
      <div class="hr-d my-5"></div>
      <div class="row align-items-center article__left">
        <div class="col-lg-5 order-lg-2 slide-right">
          <div class="view overlay text-center rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/sunshine.webp"
              alt="a-sunshine"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 order-lg-1 slide-left">
          <h4 id="nightLesson" class="mb-3">
            Cours Accélérés et Cours du Soir :<br />
            Flexibilité pour concrétiser votre projet professionnel
          </h4>
          <p>
            Si vous souhaitez obtenir rapidement votre
            <strong>carte professionnelle de taxi</strong>, notre Institut
            propose des <strong>cours accélérés</strong> adaptés à vos besoins.
            Ces <strong>formations</strong> intensives vous permettent
            d'acquérir toutes les compétences requises en un temps réduit, sans
            compromis sur la qualité de l'apprentissage. De plus, pour ceux
            ayant des contraintes d'emploi du temps, nos
            <strong>cours du soir</strong> sont la solution idéale. Vous pourrez
            suivre la <strong>formation</strong> à des horaires flexibles, en
            conciliant votre travail ou vos autres engagements. Notre objectif
            est de rendre la <strong>formation</strong> accessible à tous, c'est
            pourquoi nous acceptons le <strong>CPF</strong> et offrons des
            facilités de paiement en 3 fois sans frais. De plus, notre centre de
            formation est accessible aux
            <strong>personnes à mobilité réduite </strong>(
            <strong>PMR)</strong>, avec des installations adaptées pour garantir
            un apprentissage inclusif. Rejoignez notre Institut Taxi et réalisez
            votre projet professionnel dans les meilleures conditions.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CallToContact from "@/components/plugins/CallToContact.vue";
export default {
  name: "ServicesTaxi",
  components: { CallToContact },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", ".fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.formation {
  & h2 {
    text-align: center;
    background-color: $color-primary;
    color: $color-secondary;
    padding: 10px;
  }
  & .argument {
    font-family: "cunia";
    font-size: 1.3em;
  }
  .price-card {
    & .card {
      color: $color-text-light;
      background: #000000; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      & h3 {
        background-color: $color-primary;
        font-family: "quantum";
        color: $color-text-light;
        padding: 10px;
      }
      & .price {
        text-align: center;
        font-size: 2em;
        margin-bottom: 0px;
        & span {
          color: $color-secondary;
        }
      }
      & .priceBis {
        text-align: center;
        font-size: small;
        margin-bottom: 20px;
      }
      & .card-body {
        padding: 0px;
        & ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          list-style-type: none;
          padding: 0px;
          & li,
          p {
            text-align: center;
          }
        }
      }
      & .card-footer {
        background-color: $color-secondary;
        padding: 0px;
      }
    }
  }
}
.double-block {
  @media screen and (min-width: 992px) {
    padding: 20px 25px;
    margin: 0px 50px;
  }
  &__title {
    text-align: center;
    margin: 70px 5px 50px;
    & h2 {
      color: $color-primary;
    }
    @media screen and (min-width: 992px) {
      margin: 100px 0px;
    }
  }
  &__articles {
    margin: 0px 5px;
    .article {
      @media screen and (min-width: 992px) {
        margin: 0px 40px;
      }
      & h4 {
        color: $color-secondary;
      }
      &__left {
        & h4 {
          color: $color-primary;
        }
      }
    }
  }
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
.fall.active {
  @include fall-down(1s);
}
</style>
