<template>
  <header :style="{ backgroundImage: `url('${backgroundImage}')` }">
    <div class="item">
      <div class="container">
        <div class="header-content">
          <div class="line"></div>
          <p>Formations de qualité</p>
          <h1>Devenez Chauffeur VTC à {{ town.town }}</h1>
          <p>
            Rejoignez notre Institut et lancez votre carrière de
            <strong>chauffeur VTC</strong>.
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="toggle-section">
    <div class="container mt-5">
      <!--Section: Content-->
      <section class="formation mb-5">
        <!-- Section heading -->
        <h2 class="text-center font-weight-bold">
          Formations VTC pour {{ town.town }}
        </h2>
        <!-- Section description -->
        <div class="mx-auto w-responsive mb-5">
          <div class="container mb-5">
            <!--Section: Content-->
            <section class="text-center">
              <!-- Section heading -->
              <p
                class="text-center argument font-weight-bold dark-grey-text pb-2 my-4"
              >
                <strong>Prix formations vtc pour {{ town.town }}</strong>
              </p>
              <!-- Section description -->
              <p class="text-center w-responsive mx-auto mb-5">
                Découvrez nos <strong>formations VTC</strong>, conçues pour vous
                former aux exigences spécifiques du métier de
                <strong>chauffeur VTC</strong> et vous aider à exceller dans ce
                domaine en plein essor.
              </p>

              <!-- Grid row -->
              <div class="row">
                <!-- Grid column -->
                <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                  <!-- Pricing card -->
                  <div class="card pricing-card white-text">
                    <!-- Price -->
                    <div class="aqua-gradient rounded-top">
                      <h3 class="option">INITIALE</h3>
                    </div>

                    <!-- Features -->
                    <div class="card-body">
                      <p class="price">1350<span>€</span></p>
                      <ul>
                        <li>
                          <p>90H / 3 semaines</p>
                        </li>
                        <li>
                          <p>Lundi au Vendredi</p>
                          <p>9h30-12h30<br />13h30-16h30</p>
                        </li>
                        <li>
                          <p>Éligible CPF</p>
                        </li>
                        <li>
                          <p>Paiement en 3x sans frais</p>
                        </li>
                        <li>
                          <p>3 séances<br />de conduite</p>
                        </li>
                        <li>
                          <p>
                            Véhicule mis à disposition<br />le jour de l'examen
                          </p>
                        </li>
                      </ul>
                      <div class="card-footer">
                        <router-link to="/formation-vtc-initiale">
                          <button
                            name="button"
                            class="mb-3 mt-3 btn purple-gradient btn-rounded"
                          >
                            Détails &#187;
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
                <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                  <!-- Pricing card -->
                  <div class="card pricing-card white-text">
                    <!-- Price -->
                    <div class="aqua-gradient rounded-top">
                      <h3 class="option">INITIALE-soir</h3>
                    </div>

                    <!-- Features -->
                    <div class="card-body">
                      <p class="price">1350<span>€</span></p>
                      <ul>
                        <li>
                          <p>60H / 4 semaines</p>
                        </li>
                        <li>
                          <p>Lundi au Vendredi</p>
                          <p>18h00<br />21h00</p>
                        </li>
                        <li>
                          <p>Éligible CPF</p>
                        </li>
                        <li>
                          <p>Paiement en 3x sans frais</p>
                        </li>
                        <li>
                          <p>3 séances<br />de conduite</p>
                        </li>
                        <li>
                          <p>
                            Véhicule mis à disposition<br />le jour de l'examen
                          </p>
                        </li>
                      </ul>
                      <div class="card-footer">
                        <router-link to="/formation-vtc-initiale">
                          <button
                            name="button"
                            class="mb-3 mt-3 btn purple-gradient btn-rounded"
                          >
                            Détails &#187;
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
                <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                  <!-- Pricing card -->
                  <div class="card pricing-card white-text">
                    <!-- Price -->
                    <div class="aqua-gradient rounded-top">
                      <h3 class="option">INITIALE-accéléré</h3>
                    </div>

                    <!-- Features -->
                    <div class="card-body">
                      <p class="price">1350<span>€</span></p>
                      <ul>
                        <li>
                          <p>15H / 1 semaines</p>
                        </li>
                        <li>
                          <p>Lundi au Vendredi</p>
                          <p>18h00<br />21h00</p>
                        </li>
                        <li>
                          <p>Éligible CPF</p>
                        </li>
                        <li>
                          <p>Paiement en 3x sans frais</p>
                        </li>
                        <li>
                          <p>3 séances<br />de conduite</p>
                        </li>
                        <li>
                          <p>
                            Véhicule mis à disposition<br />le jour de l'examen
                          </p>
                        </li>
                      </ul>
                      <div class="card-footer">
                        <router-link to="/formation-vtc-initiale">
                          <button
                            name="button"
                            class="mb-3 mt-3 btn purple-gradient btn-rounded"
                          >
                            Détails &#187;
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Grid row -->
              <div class="row justify-content-center">
                <!-- Grid column -->
                <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                  <!-- Pricing card -->
                  <div class="card pricing-card white-text">
                    <!-- Price -->
                    <div class="peach-gradient rounded-top">
                      <h3 class="option">CONTINUE</h3>
                    </div>

                    <!-- Features -->
                    <div
                      class="card-body striped orange-striped card-background"
                    >
                      <p class="price">170<span>€</span></p>
                      <ul>
                        <li>
                          <p>14h / 2 jours</p>
                        </li>
                        <li>
                          <p>Disponibilté<br />à convenir</p>
                        </li>
                      </ul>
                      <div class="card-footer">
                        <router-link to="/formation-vtc-continue">
                          <button
                            name="button"
                            class="mb-3 mt-3 btn purple-gradient btn-rounded"
                          >
                            Détails &#187;
                          </button>
                        </router-link>
                      </div>
                    </div>
                    <!-- Features -->
                  </div>
                  <!-- Pricing card -->
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                  <!-- Pricing card -->
                  <div class="card pricing-card white-text">
                    <!-- Price -->
                    <div class="purple-gradient rounded-top">
                      <h3 class="option">PRATIQUE SEULE</h3>
                    </div>

                    <!-- Features -->
                    <div
                      class="card-body striped purple-striped card-background"
                    >
                      <p class="price">390<span>€</span></p>
                      <ul>
                        <li>
                          <p>3 séances de conduite</p>
                        </li>
                        <li>
                          <p>
                            Véhicule mis à disposition<br />le jour de l'examen
                          </p>
                        </li>
                      </ul>
                      <div class="card-footer">
                        <router-link to="/formation-vtc-pratique-seule">
                          <button
                            name="button"
                            class="mb-3 mt-3 btn purple-gradient btn-rounded"
                          >
                            Détails &#187;
                          </button>
                        </router-link>
                      </div>
                    </div>
                    <!-- Features -->
                  </div>
                  <!-- Pricing card -->
                </div>
                <!-- Grid column -->
              </div>
            </section>
            <!--Section: Content-->
          </div>
        </div>
        <div class="hr-d"></div>
        <CallToContact
          title="Contactez nous"
          subTitle="L'equipe pédagogique de l'institut taxi s'occupe de vos démarches administratives."
        />
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>
    <section class="container-fluid double-block">
      <div class="double-block__title">
        <h2><span class="brand-pseudo">ITTI</span><br />Ecole VTC</h2>
      </div>
      <div class="dark-grey-text double-block__articles">
        <div class="row align-items-center article">
          <div class="col-lg-5 text-center slide-left">
            <div
              class="view overlay text-center rounded z-depth-2 mb-lg-0 mb-4"
            >
              <img
                src="@/assets/pictures/vtc-course.webp"
                alt="a-car-in-paris"
                class="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
          <div class="col-lg-7 slide-right">
            <h2 class="mb-3">
              {{ town.town }} Formations VTC :<br />
              Un tremplin vers une carrière polyvalente et passionnante
            </h2>
            <p>
              Découvrez notre Institut Taxi, votre partenaire de confiance pour
              des formations de qualité dans le domaine du <strong>VTC</strong>.
              Nous vous proposons une
              <strong>formation vtc initiale</strong> complète qui vous
              permettra d'acquérir les compétences essentielles pour
              <strong>devenir un chauffeur VTC</strong> professionnel et
              accompli. Notre programme couvre divers aspects tels que la
              réglementation, la sécurité routière, les techniques de conduite
              défensive, la gestion des itinéraires, ainsi que les notions
              fondamentales du service client. Vous serez formé par des
              professionnels expérimentés qui vous guideront tout au long du
              processus d'apprentissage et vous prépareront efficacement pour
              les examens pratiques et théoriques. De plus, nos
              <strong>formations vtc</strong> sont éligibles au CPF, ce qui
              signifie que vous pourrez les suivre gratuitement et sans frais
              supplémentaires.
            </p>
          </div>
        </div>
        <div class="hr-d my-5"></div>
        <div class="row align-items-center mb-5 article__left">
          <div class="col-lg-5 order-lg-2 slide-right text-center">
            <div
              class="view overlay text-center rounded z-depth-2 mb-lg-0 mb-4"
            >
              <img
                src="@/assets/pictures/sunny.webp"
                alt="rising-sun"
                class="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
          <div class="col-lg-7 order-lg-1 slide-left">
            <h2 class="mb-3">
              {{ town.town }} Devenir VTC :<br />
              Accélérés et Cours du Soir
            </h2>
            <p>
              Chez Institut Taxi, nous comprenons que vous avez peut-être des
              contraintes de temps et que vous souhaitez obtenir rapidement
              votre <strong>carte professionnelle VTC</strong>. C'est pourquoi
              nous proposons des <strong>cours accélérés</strong> qui vous
              permettent d'acquérir toutes les compétences nécessaires dans un
              laps de temps réduit. Ces formations intensives vous offrent la
              possibilité d'obtenir <strong>votre carte VTC</strong> plus
              rapidement, tout en bénéficiant d'un enseignement de qualité. Par
              ailleurs, si vous avez des engagements professionnels ou
              personnels en journée, nos cours du soir sont conçus pour
              s'adapter à votre emploi du temps chargé. Vous pourrez ainsi
              suivre la formation à des horaires flexibles, tout en poursuivant
              vos autres activités. De plus, nous acceptons le CPF pour
              <strong>votre formation vtc</strong> et proposons un paiement
              échelonné en 3 fois sans frais pour faciliter l'accès à nos
              formations. Que vous souhaitez faire une
              <strong
                >reconversion professionnelle en tant que chauffeur VTC</strong
              >
              ou que vous souhaitiez élargir vos compétences, l'Institut Taxi
              est là pour vous accompagner tout au long de votre parcours de
              <strong>formation en tant que VTC.</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!---------------------------seperation------------------------------------------------>
  <HomeService />
  <!-----------------------section------------------------------------------------------->
  <div class="toggle-section">
    <section class="container-fluid double-block">
      <div class="double-block__title">
        <h2>
          <span class="brand-pseudo">ITTI</span><br />Nos Formations pour VTC de
          {{ town.town }}
        </h2>
      </div>
      <div class="dark-grey-text double-block__articles">
        <div class="row align-items-center article">
          <div class="col-lg-5 slide-left text-center">
            <div
              class="view overlay text-center rounded z-depth-2 mb-lg-0 mb-4"
            >
              <img
                src="@/assets/pictures/itti-front.webp"
                alt="the school vtc outside viaw"
                class="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
          <div class="col-lg-7 slide-right">
            <h2 class="mb-3">
              Formations initiale VTC pour {{ town.town }} :<br />
              Une Formation de Qualité
            </h2>
            <p>
              À l'Institut Taxi, nous nous adressons particulièrement à ceux qui
              débutent leur carrière de <strong>chauffeur VTC</strong>, à ceux
              en reconversion professionnelle, et à tous ceux qui souhaitent
              <strong>devenir VTC à {{ town.town }}</strong
              >. Nos
              <strong>formations VTC sont éligibles au financement CPF</strong>,
              ce qui permet à nos stagiaires de bénéficier d'une
              <strong>formation VTC gratuite</strong>. Nous offrons une grande
              flexibilité avec des options pour une
              <strong>formation VTC accélérée</strong> en soirée, le week-end,
              ainsi que des programmes plus longs pour ceux qui préfèrent un
              apprentissage progressif. En cas d'échec à l'<strong
                >examen VTC</strong
              >, vous êtes les bienvenus pour recommencer la formation avec une
              nouvelle session, où seuls les frais d'examen seront à votre
              charge. Notre accompagnement inclut un accompagnement pour obtenir
              votre <strong>carte professionnelle VTC</strong>, des conseils sur
              le choix de <strong>votre véhicule VTC</strong>, et une
              <strong>formation continue</strong> pour améliorer vos compétences
              et renouveler votre <strong>carte pro VTC</strong>. Dans votre
              parcours pour <strong>devenir VTC</strong> L'institut taxi vous
              offre un soutien administratif pour vous guider à chaque étape de
              votre parcours professionnel et vous permettre de vous concentrer
              à 100% sur votre <strong>carrière VTC</strong>.L'Institut Taxi
              vous accompagne vers la réussite de votre
              <strong>formation VTC</strong>.
            </p>
          </div>
        </div>
        <div class="hr-d my-5"></div>
        <div class="row align-items-center article__left">
          <div class="col-lg-5 order-lg-2 slide-right text-center">
            <div
              class="view overlay text-center rounded z-depth-2 mb-lg-0 mb-4"
            >
              <img
                src="@/assets/pictures/room-out.webp"
                alt="class room vtc formation"
                class="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
          <div class="col-lg-7 order-lg-1 slide-left">
            <h2 class="mb-3">
              Renouvellement carte pro VTC pour {{ town.town }} :<br />
              Formation Continue VTC
            </h2>
            <p>
              La <strong>formation continue VTC</strong> est cruciale pour
              maintenir à jour les compétences et se conformer aux exigences
              réglementaires du secteur du transport de personnes. Obligatoire
              pour tous les chauffeurs
              <strong>VTC à {{ town.town }}</strong> titulaires d'une carte
              professionnelle. La <strong>formation VTC continue</strong> dure
              14 heures, renouvelable tous les cinq ans, elle permet de rester
              informé des dernières réglementations et des évolutions du secteur
              <strong>vtc a {{ town.town }}</strong
              >. En suivant cette <strong>formation VTC continue</strong>, les
              chauffeurs peuvent actualiser leurs connaissances en matière de
              réglementation spécifique aux <strong>VTC</strong>. La
              <strong>formation continue VTC</strong> comprend quatre modules
              indispensables pour le renouvellement de la
              <strong>carte professionnelle VTC</strong>. Chaque module de la
              <strong>formation VTC continue</strong> dure 3h30 et couvre des
              domaines essentiels. Le module sur le droit du transport public
              particulier de personnes vous permet de comprendre les
              réglementations légales en vigueur. Le module de réglementation
              spécifique liée à l’activité de
              <strong>vtc à {{ town.town }}</strong> fournit des informations
              cruciales pour distinguer les exigences et les pratiques des
              <strong>VTC</strong> par rapport aux Taxi. La sécurité routière
              est un autre aspect fondamental, où vous apprendrez les meilleures
              pratiques pour assurer la sécurité de vos passagers et de
              vous-même. Enfin, le module d'anglais est conçu pour améliorer vos
              compétences linguistiques, facilitant ainsi la communication avec
              une clientèle internationale. Ces modules sont conçus pour offrir
              une mise à jour complète et approfondie des connaissances
              nécessaires pour exceller en tant que
              <strong>chauffeur VTC à {{ town.town }}</strong
              >.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
import { useMeta } from "vue-meta";
import { toRef, watchEffect } from "vue";
import HomeService from "@/components/home/HomeService.vue";

export default {
  components: { HomeService },
  name: "CreationRead",
  props: {
    town: {
      type: Object,
      required: true,
    },
    backgroundImage: String,
  },
  setup(props) {
    const townProp = toRef(props, "town");

    watchEffect(() => {
      if (townProp.value) {
        const sentenceBegin = "Devenir chauffeur VTC à ";
        const sentenceEnd =
          " et découvrez notre formation initiale VTC complète pour devenir un chauffeur de vtc professionnel qualifié. Apprenez les compétences essentielles en matière de réglementation, de sécurité routière, de gestion et de service client.";

        const metaDescription =
          sentenceBegin + townProp.value.content + sentenceEnd;

        const townSlug = function (input) {
          return input.toLowerCase().replace(/\s+/g, "-");
        };
        const townValue = townProp.value.town;
        const slugTown = townSlug(townValue);

        useMeta({
          title: "Devenir VTC à " + townValue,
          meta: [
            {
              name: "description",
              content: metaDescription,
            },
          ],
          link: [
            {
              rel: "canonical",
              href:
                "https://www.malaweb.fr/creation-site-internet/" +
                slugTown +
                "/" +
                townProp.value.id,
            },
          ],
        });
      }
    });

    return {
      townData: townProp, // Utiliser un nom unique pour la clé retournée
    };
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", ".fall");
    });
  },
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    hasBackgroundImage() {
      return this.backgroundImage !== undefined;
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      Town: "StateTown",
    }),
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth", // Défilement fluide
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding-top: 30px;
  @media screen and (min-width: 992px) {
    padding-top: 80px;
  }
  @include background-responsive;
  & .item {
    width: 100vw;
    height: fit-content;
    min-height: 50vh;
    padding: 75px 0;
    background: #faf6086c;
  }
}
.header-content {
  position: relative;
  overflow: hidden;
  padding: 30px 30px 0px 40px;
  @media screen and (min-width: 992px) {
    padding: 56px;
  }
  & .line {
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border: 9px solid $color-primary;
    -webkit-clip-path: polygon(100% 0, 40% 0, 64% 100%, 100% 100%);
    clip-path: polygon(100% 0, 40% 0, 64% 100%, 100% 100%);
  }
  & p:first-of-type {
    font-family: "adam";
    color: #fff;
    font-size: 1.1em;
    @media screen and (min-width: 992px) {
      font-size: 1.6em;
    }
  }
  & h1 {
    font-family: "cunia";
    font-size: 1.5em;
    margin: 5px 0 20px;
    word-spacing: 3px;
    color: #fff;
    @media screen and (min-width: 992px) {
      font-size: 2.5em;
    }
  }
  & p:last-of-type {
    font-family: "adam";
    font-size: 1.1em;
    line-height: 1.4em;
    color: #fff;
    @media screen and (min-width: 992px) {
      font-size: 1.4em;
    }
  }
}
.toggle-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.formation {
  & h2 {
    text-align: center;
    background-color: $color-primary;
    color: $color-secondary;
    padding: 10px;
  }
  & .argument {
    font-family: "cunia";
    font-size: 1.3em;
  }
  .price-card {
    & .card {
      color: $color-text-light;
      background: #000000; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      & h3 {
        background-color: $color-primary;
        font-family: "quantum";
        color: $color-text-light;
        padding: 10px;
      }
      & .price {
        text-align: center;
        font-size: 2em;
        & span {
          color: $color-secondary;
        }
      }
      & .card-body {
        padding: 0px;
        & ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          list-style-type: none;
          padding: 0px;
          & li,
          p {
            text-align: center;
          }
        }
      }
      & .card-footer {
        background-color: $color-secondary;
        padding: 0px;
      }
    }
  }
}
.double-block {
  @media screen and (min-width: 992px) {
    padding: 20px 25px;
    margin: 0px 50px;
  }
  &__title {
    text-align: center;
    margin: 70px 5px 50px;
    & h2 {
      color: $color-primary;
    }
    @media screen and (min-width: 992px) {
      margin: 100px 0px;
    }
  }
  &__articles {
    margin: 0px 5px;
    .article {
      @media screen and (min-width: 992px) {
        margin: 0px 40px;
      }
      & h4 {
        color: $color-secondary;
      }
      &__left {
        & h4 {
          color: $color-primary;
        }
      }
    }
  }
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
.fall.active {
  @include fall-down(1s);
}
</style>
