import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { createMetaManager } from "vue-meta";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://api-blog.institut-taxi.fr/api/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

axios.interceptors.request.use(
  (config) => {
    let token = store.getters.StateToken;
    if (token) {
      /*if (cookie) {
        token = `${token}.${cookie[1]}`;
      }*/
      config.headers["Authorization"] = `Bearer ${token}`;
      //config.headers["Content-Type"] = "application/json";
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 404 && !originalRequest._retry) {
      originalRequest._retry = true;
      // store.dispatch("LogOut");
      return router.push("/error");
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // store.dispatch("LogOut");
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});

// create a global method for formatting the date string
const formatDate = (dateString) => {
  if (!dateString) {
    return "";
  }
  const options = { day: "numeric", month: "numeric", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", options);
};

// make the formatDate method available to all components
createApp(App)
  .use(store)
  .use(router)
  .use(createMetaManager())
  .mixin({
    methods: {
      formatDate: formatDate,
    },
  })
  .mount("#app");
