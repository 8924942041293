<template>
  <div class="container-fluid callToContact">
    <div class="callToContact__header">
      <h4>{{ title }}</h4>
      <p>
        {{ subTitle }}
      </p>
    </div>
    <section class="callToContact-icons">
      <div class="bg-blue">
        <a href="tel:+33787185918">
          <div class="callToContact-icons__group">
            <p class="yellow">Nous<br />appelés</p>
            <div class="allIcons">
              <img
                src="@/assets/icons/icons-appel-yellow.webp"
                alt="icon-taxi"
                loading="lazy"
              />
            </div>
          </div>
        </a>
      </div>
      <div class="callToContact-icons__group mx-2">
        <div class="allIcons">
          <div class="pipe"></div>
        </div>
      </div>
      <div class="bg-yellow">
        <div class="callToContact-icons__group">
          <a href="sms:+33787185918">
            <p class="blue">Posez votre question<br />par SMS</p>
            <div class="allIcons">
              <img
                src="@/assets/icons/icons-sms-blue.webp"
                alt="icon-sms"
                loading="lazy"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="callToContact-icons__group mx-2">
        <div class="allIcons">
          <div class="pipe"></div>
        </div>
      </div>
      <div class="bg-blue">
        <div class="callToContact-icons__group">
          <a href="/contact">
            <p class="yellow">Prendre un<br />rendez-vous</p>
            <div class="allIcons">
              <img
                src="@/assets/icons/icons-calendar.webp"
                alt="icon-calendar"
                loading="lazy"
              />
            </div>
          </a>
        </div>
      </div>
      <div class="callToContact-icons__group mx-2">
        <div class="allIcons">
          <div class="pipe"></div>
        </div>
      </div>
      <div class="bg-yellow">
        <div class="callToContact-icons__group">
          <a @click.prevent="showCallForm" href="#">
            <p class="blue">vous souhaitez<br />être appelés ?</p>
            <div class="allIcons">
              <img
                src="@/assets/icons/icons-incoming.webp"
                alt="icon-incoming-call"
                loading="lazy"
              />
            </div>
          </a>
        </div>
      </div>
    </section>
    <span v-if="callMeMaybe">
      <section class="col-10 col-lg-5 formulaire">
        <span class="close-cross" @click="closeOrder">X</span>
        <div class="formulaire__header">
          <p>
            Laissez nous vos coordonnées notre équipe vous recontact en moins de
            24h.<span>*</span><br />
            <span>*</span>(24h ouvrées)
          </p>
        </div>
        <form ref="formData" @submit.prevent="sendEmail">
          <div class="form">
            <div class="col">
              <input
                type="hidden"
                name="reason"
                value="Demande d'appel"
                required
              />
            </div>
            <div class="col">
              <input
                type="text"
                placeholder="Votre Nom"
                name="name"
                maxlength="30"
                required
              />
            </div>
            <div class="col">
              <input
                type="tel"
                placeholder="Votre Téléphone"
                name="phone"
                required
                pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
              />
            </div>
            <span v-if="this.isCaptchaVisible">
              <vue-hcaptcha
                sitekey="223d82ce-7279-4269-99d2-ba9276578c24"
                @verify="onVerify"
                @error="onError"
              ></vue-hcaptcha>
            </span>
            <button name="button" type="submit">Envoyer</button>
          </div>
        </form>
        <span v-if="this.modalConfirm">
          <ConfirmModal
            @closeModalConfirm="closeModalConfirm"
            title="Merci de votre intérêt"
            message="Vous allez être recontactés dans les plus brefs délais"
            btnExit="À bientôt"
          />
        </span>
      </section>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  name: "CallTocontact",
  components: { VueHcaptcha, ConfirmModal },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subTitle: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      callMeMaybe: false,
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendCallMeMail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendCallMeMail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      this.isCaptchaVerified = true;
      console.log("Verified: ", { token, eKey });
      console.log(this.isCaptchaVerified);
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
      this.callMeMaybe = false;
    },
    showCallForm() {
      this.callMeMaybe = true;
    },
    closeOrder() {
      this.callMeMaybe = false;
    },

    goCalendar() {
      this.$router.push({ path: "/contact", hash: "calendar" });
    },
  },
};
</script>

<style lang="scss" scoped>
.callToContact {
  margin: 70px 0px;
  @media screen and (min-width: 992px) {
    margin: 90px 0px;
  }
  &__header {
    text-align: center;
    & h4 {
      font-family: "adam";
      font-size: 1.8em;
      color: $color-primary;
      @media screen and (min-width: 992px) {
        font-size: 2em;
      }
    }
    & p {
      text-align: left;
      margin: 40px 0px 60px;
      margin-left: 0.7rem;
      @media screen and (min-width: 992px) {
        text-align: center;
        margin: 40px 30px 60px;
      }
    }
  }

  & .callToContact-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      align-items: flex-end;
    }
    & .bg-blue {
      background-color: $color-primary;
      border-radius: 10px 2px;
      border: 0.3px outset $color-secondary;
    }
    & .bg-yellow {
      background-color: $color-secondary;
      border-radius: 2px 10px;
      border: 0.1px outset $color-primary;
    }
    & .bg-blue,
    .bg-yellow {
      width: 100%;
      transition: 0.4s;
      &:hover {
        scale: 94%;
        border-style: inset;
      }
    }
    a {
      & .yellow,
      .blue {
        color: $color-text-light;
        font-family: "adam";
        font-weight: bolder;
      }
      & .blue {
        color: $color-text-light;
      }
    }
    &__group {
      text-align: center;
      margin-bottom: 20px;
      & img {
        width: 70px;
      }
      & p {
        font-size: 1em;
        text-align: center;
        margin: 10px 0px;
        font-family: "adam";
      }
      & .allIcons {
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
        & .pipe {
          display: none;
          @media screen and (min-width: 992px) {
            @include black-gradient;
            width: 1.5px;
            height: 40px;
          }
        }
      }
    }
  }
  & .formulaire {
    position: fixed;
    top: 58%;
    left: 50%;
    width: 90%;
    max-width: 800px;
    transform: translate(-50%, -50%);
    z-index: 500;
    padding: 10px 50px 20px;
    background-color: $color-primary;
    border: 0.5px outset $color-secondary;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    .close-cross {
      position: fixed;
      top: -10px;
      left: -10px;
      display: inline;
      color: $color-secondary;
      @include black-gradient;
      padding: 5px 6px;
      border-radius: 50%;
      font-size: 1em;
      cursor: pointer;
      box-sizing: content-box;
      transition: 0.4s;
      @media screen and (min-width: 992px) {
        top: -28px;
        left: -18px;
      }
      &:hover {
        border: 0.1px outset rgba(255, 0, 0, 0.685);
        color: rgba(255, 0, 0, 0.685);
      }
    }
    &__header {
      margin: 10px 0px 20px;
      & p {
        color: $color-text-light;
        font-size: 1em;
        text-align: center;
        & span {
          color: $color-secondary;
        }
      }
    }

    h2 {
      text-align: center;
      text-transform: uppercase;
    }
    & .form {
      background-color: $color-secondary;
      padding: 20px 10px 20px;
      border-radius: 5px;
      text-align: center;
      input {
        margin-bottom: 20px;
        width: 90%;
        padding: 10px;
        border-radius: 10px;
        &:focus {
          box-shadow: 1px 1px 3px black;
        }
        &::placeholder {
          color: $color-primary;
        }
      }
      button {
        border: none;
        padding: 7px 20px;
        border-radius: 100px;
        font-size: 1em;
        letter-spacing: 1px;
        background-color: $color-primary;
        color: white;
        opacity: 0.8;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.884);
        transition: 0.3s;
        &:hover {
          opacity: 1;
          scale: 96%;
        }
        &:active {
          scale: 96%;
          box-shadow: 1px 1px 3px black;
        }
      }
    }
  }
}
.g-recaptcha {
  margin-bottom: 30px;
}
</style>
