<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('formation-taxi')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation Taxi Initiale" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/formation-taxi-initiale.webp"
                          alt="road-timelaps-night"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">TAXI INITIALE</h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />1350€</p>
                            <!-- Twitter -->
                            <p><span>Durée</span><br />90h / 3 semaines</p>
                            <!-- Google+ -->
                            <p>
                              <span>Voiture de prêt</span><br />pour l'examen
                            </p>
                            <!-- Comments -->
                            <p><span>Financement</span><br />Eligible CPF</p>
                            <p><span>paiement</span><br />3x sans frais</p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Formation taxi initiale</h2>
                      <p>
                        La <strong>formation initiale</strong> pour
                        <strong>devenir chauffeur de taxi</strong> est une étape
                        essentielle pour acquérir les compétences nécessaires à
                        cette profession et obtenir la
                        <strong>carte professionnelle taxi</strong>. Elle se
                        déroule sur une durée de 90 heures réparties sur 3
                        semaines. Les cours ont lieu de 9h30 à 12h30 et de 13h30
                        à 16h30, avec la possibilité de suivre des cours
                        accélérés en soirée de 18h à 21h et même le weekend.
                        Cette <strong>formation</strong>, d'un coût de 1350€,
                        est éligible au Compte Personnel de Formation (CPF) et
                        offre également la possibilité de paiement en 3 fois
                        sans frais. Durant la formation, une voiture est mise à
                        disposition pour l'examen pratique, et en cas d'échec à
                        l'<strong>examen théorique</strong>, une deuxième chance
                        est accordée. Les
                        <strong>épreuves théoriques</strong> comprennent des
                        connaissances sur la réglementation, la gestion, la
                        sécurité routière, ainsi que des capacités d'expression
                        et de compréhension en français et en anglais.
                      </p>
                      <div class="desk-card">
                        <div>
                          <h2>Épreuves théoriques taxi</h2>
                          <div class="card">
                            <div class="card-body">
                              <h3 class="card-subtitle card-title mb-2">
                                5 épreuves théoriques "Tronc commun"
                              </h3>

                              <ul class="list-group">
                                <li class="list-group-item">
                                  A – Réglementation du
                                  <strong
                                    >transport public particulier de
                                    personnes</strong
                                  >
                                  (coeff 3, note éliminatoire inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  B – Gestion (coeff 2, note éliminatoire
                                  inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  C - Sécurité routière (coeff 3, note
                                  éliminatoire inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  D – Capacité d'expression et de compréhension
                                  en langue française, sur la base d'un texte de
                                  15 à 20 lignes (coeff 2, note éliminatoire
                                  inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  E – Capacité d'expression et de compréhension
                                  en langue anglaise (coeff 1, note éliminatoire
                                  inférieure à 4/20)
                                </li>
                              </ul>

                              <h4 class="card-subtitle mt-3 mb-2">
                                2 épreuves spécifiques d'admissibilité
                              </h4>

                              <ul class="list-group">
                                <li class="list-group-item">
                                  F(T) Connaissance du territoire et la
                                  réglementation locale (coeff 3, note
                                  éliminatoire inférieure à 6/20)
                                </li>
                                <li class="list-group-item">
                                  G(T) Réglementation nationale de l'activité
                                  taxis ainsi que sur la gestion propre à
                                  l'activité (coeff 3, note éliminatoire
                                  inférieure à 6/20)
                                </li>
                              </ul>
                              <div class="card-footer text-muted">
                                <p class="card-text mt-3">
                                  Méthode d'évaluation : QCM et QRC
                                </p>
                                <p class="card-text">
                                  Seuil d'admissibilité : 10 / 20 (sans note
                                  éliminatoire)
                                </p>
                                <p class="card-text">
                                  Validité de 1 an pour 3 présentations à
                                  l'examen pratique.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h2>Examen pratique taxi</h2>
                          <div class="card">
                            <div class="card-body">
                              <h3 class="card-title card-subtitle">
                                Épreuves pratiques d'admission
                              </h3>
                              <ul class="list-group">
                                <li class="list-group-item">
                                  OBJECTIF : évaluer la capacité du candidat à
                                  assurer, par sa conduite en circulation, la
                                  sécurité des passagers et des autres usagers
                                  de la route tout en proposant aux clients un
                                  service commercial de qualité.
                                </li>
                                <li class="list-group-item">
                                  Mise en situation pratique de réalisation
                                  d'une course de taxi.
                                </li>
                              </ul>
                              <ul class="list-group">
                                <li class="list-group-item">
                                  Seuil d'admission à l'examen : 12 / 20 à
                                  l'<strong>épreuve pratique</strong>.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2>Examen t3p taxi</h2>
                      <p>
                        L'<strong>examen T3P</strong> (<strong
                          >Transport Public Particulier de Personnes</strong
                        >) est une étape clé pour obtenir la
                        <strong
                          >carte professionnelle de chauffeur de taxi</strong
                        >. Les
                        <strong>épreuves théoriques</strong> d'admissibilité
                        évaluent les connaissances du candidat dans différents
                        domaines tels que la réglementation, la gestion, la
                        sécurité routière et les langues. La réussite à ces
                        épreuves nécessite d'obtenir une note supérieure ou
                        égale à 10/20, sans note éliminatoire. Une fois
                        l'admissibilité obtenue, le candidat passe à l'épreuve
                        pratique d'admission qui évalue sa capacité à assurer la
                        sécurité des passagers et des autres usagers de la route
                        tout en offrant un service commercial de qualité. Pour
                        réussir cette épreuve, le seuil d'admission est fixé à
                        12/20. Il est important de se préparer rigoureusement
                        pour l'<strong>examen T3P</strong> afin de pouvoir
                        <strong
                          >exercer la profession de chauffeur de taxi</strong
                        >
                        de manière compétente et responsable.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "TaxiInitiale",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "Devenir chauffeur Taxi",
      description: [
        {
          content:
            "Découvrez notre formation initiale Taxi complète pour devenir un chauffeur de taxi professionnel qualifié. Apprenez les compétences essentielles en matière de réglementation, de sécurité routière, de gestion et de service client.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-taxi-initiale",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
