<template>
  <div class="contact">
    <HeaderBlue
      anteTitle="Besoin d'un renseignement ?"
      title="Discutons de vos projets de formation taxi et vtc"
      postTitle="L'institut-taxi s'occupe de vos démarches administratives pour toutes nos formations vtc et taxi"
      :backgroundImage="require('@/assets/pictures/itti-hall.webp')"
    />
    <ContactForm />
  </div>
</template>

<script>
import HeaderBlue from "@/components/headers/HeaderBlue.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import { useMeta } from "vue-meta";
export default {
  components: {
    HeaderBlue,
    ContactForm,
  },
  setup() {
    useMeta({
      title: "ITTI Formations",
      description: [
        {
          content:
            "Contactez l'Institut Taxi pour toutes vos questions et demandes. Notre équipe est là pour vous fournir les informations nécessaires sur nos formations, stages de récupération de points T3P et services. N'hésitez pas à nous contacter dès maintenant.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/contact",
        },
      ],
    });
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      // Vérifier si la page est déjà en haut
      if (window.pageYOffset === 0) {
        return;
      }

      // Faire défiler vers le haut de la page
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
