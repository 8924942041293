<template>
  <div class="contact-card mt-4">
    <h5 class="jaapokki text-center mb-3">Nous Contacter</h5>
    <ul class="list-unstyled">
      <li>
        <router-link to="/contact">
          <div>
            <i class="fas fa-home"></i>
            <p>
              <strong
                >10 rue de la Sablière,<br />
                92230 Gennevilliers</strong
              >
            </p>
          </div>
        </router-link>
      </li>

      <li>
        <a href="mailto:contact@malaweb.fr">
          <div>
            <i class="fas fa-envelope"></i>
            <p>contact<span class="arobase">@</span>institut-taxi.fr</p>
          </div>
        </a>
      </li>
      <li>
        <a href="tel:+33141112232">
          <div>
            <i class="fas fa-phone"></i>
            <p>01 41 11 22 32</p>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "contactCard",
};
</script>

<style lang="scss" scoped>
h5 {
  color: $color-primary;
}
.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & li {
    margin-bottom: 10px;
    padding: 3px 10px;
    border: 0.5px $color-text-light outset;
    border-radius: 10px;
    width: 100%;
    font-size: 0.6em;
    transition: 0.2s;
    &:hover {
      background-color: $color-primary;
      border: 0.5px $color-text-light inset;
      color: $color-text;
      & i {
        color: $color-secondary;
      }
      & p {
        color: $color-text-light;
      }
    }
    & .arobase {
      font-size: 2em;
    }
  }
  & div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  & a,
  router-link {
    color: rgba(230, 221, 221, 0.904);
    &:hover {
      color: $color-secondary;
    }
    & p {
      margin: 5px;
      color: $color-primary;
      &:hover {
        color: $color-text-light;
      }
    }
  }
  & i {
    color: $color-primary;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 8px;
  }
}
</style>
