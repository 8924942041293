<template>
  <div class="container-fluid image-block">
    <div class="image-block__title">
      <h2>Examen taxi vtc</h2>
    </div>
    <div class="image-block__head">
      <p>
        L'examen <strong>T3P</strong> est une étape essentielle pour obtenir la
        <strong>carte professionnelle VTC</strong> ou taxi. Cet
        <strong>examen</strong> rigoureux évalue les connaissances et les
        compétences requises pour exercer ces métiers.
      </p>
    </div>
    <div class="image-block__wrapper">
      <div class="col-lg-4 image-block__image slide-left">
        <img
          src="@/assets/pictures/wayroom.webp"
          alt="class-room-for-taxi-learning"
          class="img-fluid"
          loading="lazy"
        />
      </div>
      <div class="col-lg-6 dark-grey-text image-block__aside slide-right">
        <p class="dark-grey-text">
          L'<span>Institut</span> <span> Taxi</span>, prépare ses stagiaires à
          l'<strong>examen</strong> <strong>T3P</strong>, en leur fournissant
          les ressources et les <strong>formations</strong> nécessaires pour
          réussir.
        </p>
        <p>
          Que vous visiez la <strong>carte professionnelle taxi</strong> ou VTC,
          notre équipe pédagogique expérimentée vous accompagnera dans votre
          préparation, en couvrant tous les aspects pertinents de l'examen
          <strong>T3P</strong>. Soyez prêt à franchir cette étape importante
          vers votre carrière de chauffeur professionnel.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFirstSection",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", "fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.image-block {
  /*display: flex;
  justify-content: space-between;*/
  padding: 90px 10px;
  text-align: center;
  @media screen and (min-width: 992px) {
    padding: 90px 10px;
  }
  &__title {
    color: $color-primary;
    margin-bottom: 40px;
    & h2 {
      font-size: 2.3em;
      @media screen and (min-width: 992px) {
        font-size: 3em;
        margin-top: 10px;
      }
    }
  }
  &__head {
    & p {
      text-align: center;
      margin: 0px 0px 50px;
      @media screen and (min-width: 992px) {
        font-size: 1.3em;
        padding: 0px 30px;
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__image {
    & img {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    }
  }

  &__aside {
    margin: 0px 0px;
    & p {
      text-align: left;
      margin-left: 0.3rem;
      color: $color-text-grey;
      font-size: 1.1em;
      margin-top: 18px;
      @media screen and (min-width: 992px) {
        margin-left: 0rem;
        margin-top: 0px;
        padding: 0rem 2.5rem;
      }
      & span:first-of-type {
        color: $color-primary;
      }
      & span:last-of-type {
        color: $color-secondary;
      }
    }
  }
  .slide-right.active {
    @include slide-right(1.2s);
  }
  .slide-left.active {
    @include slide-left(1.2s);
  }
}
</style>
