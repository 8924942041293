<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('formation-taxi')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation taxi pratique seule" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/examen-pratique-taxi.webp"
                          alt="hill-timelaps"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">TAXI PRATIQUE SEULE</h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />390€</p>
                            <!-- Twitter -->
                            <p><span>Durée</span><br />3 séances de conduite</p>
                            <!-- Google+ -->
                            <p>
                              <span>Voiture de prêt</span><br />pour l'examen
                            </p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Taxi examen pratique seul</h2>
                      <p>
                        Découvrez notre
                        <strong>formation pratique seule vtc</strong> pour
                        l'<strong>examen T3P</strong> (<strong
                          >Transport Public Particulier de Personnes</strong
                        >), spécialement conçue pour les
                        <strong>conducteurs VTC</strong>. Notre
                        <strong>formation pratique vtc</strong> vise à vous
                        préparer de manière intensive à réussir l'<strong
                          >examen pratique T3P</strong
                        >
                        et à développer les compétences nécessaires pour assurer
                        la sécurité des passagers et des autres usagers de la
                        route tout en offrant un service de qualité en tant que
                        <strong>chauffeur VTC</strong>.
                      </p>
                      <p>
                        Notre programme de formation comprend trois séances de
                        conduite dédiées, spécifiquement adaptées aux exigences
                        de l'<strong>épreuve pratique de l'examen T3P</strong>
                        pour les <strong>conducteurs VTC</strong>. Vous serez
                        encadré par nos formateurs expérimentés, qui vous
                        guideront dans l'apprentissage des techniques de
                        conduite spécifiques au transport de personnes. Vous
                        vous familiariserez avec les manœuvres requises, les
                        règles de sécurité et les bonnes pratiques pour assurer
                        une expérience de voyage optimale pour vos passagers.
                      </p>
                      <p>
                        Afin de vous offrir un environnement réaliste, nous
                        mettons à votre disposition un véhicule conforme aux
                        normes de l'<strong>examen T3P</strong> pour les
                        <strong>conducteurs VTC</strong>. Vous pourrez ainsi
                        vous entraîner dans des conditions similaires à celles
                        de l'épreuve, en vous confrontant à différentes
                        situations de conduite et en perfectionnant vos
                        compétences de gestion du véhicule et de réaction aux
                        situations routières.
                      </p>
                      <p>
                        En choisissant notre
                        <strong
                          >formation pratique pour les conducteurs VTC</strong
                        >, vous bénéficiez d'un accompagnement personnalisé et
                        de conseils avisés pour améliorer votre conduite et
                        développer vos compétences spécifiques à l'<strong
                          >activité de VTC</strong
                        >. Notre équipe pédagogique expérimentée est là pour
                        répondre à toutes vos questions et vous fournir les
                        connaissances nécessaires pour réussir brillamment
                        l'<strong>épreuve pratique de l'examen T3P</strong>.
                      </p>
                      <p>
                        Ne laissez rien au hasard dans votre préparation.
                        Rejoignez notre
                        <strong
                          >formation pratique pour les conducteurs VTC</strong
                        >
                        et augmentez vos chances de réussite à l'<strong
                          >examen T3P</strong
                        >. Avec un investissement de seulement 390€, vous
                        accédez à un programme de qualité, axé sur la pratique
                        et spécialement conçu pour vous aider à atteindre vos
                        objectifs en tant que <strong>chauffeur VTC</strong>.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "TaxiPratiqueSeule",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "examen pratique seule taxi",
      description: [
        {
          content:
            "Préparez-vous à l'examen pratique Taxi avec notre formation complète. Évaluez vos compétences de conduite en circulation, la sécurité des passagers et des autres usagers de la route, ainsi que votre capacité à offrir un service client de qualité.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-taxi-pratique-seule",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
