import axios from "axios";

const state = {
  towns: null,
  town: null,
};

const getters = {
  StateTowns: (state) => state.towns,
  StateTown: (state) => state.town,
};

const actions = {
  async GetTowns({ commit }) {
    let response = await axios.get("towns");
    commit("setTowns", response.data["hydra:member"]);
  },

  async GetTown({ commit }, townId) {
    let response = await axios.get("towns/" + townId);
    commit("setTown", response.data);
  },
  async GetTownBySlug({ commit }, slug) {
    let response = await axios.get("towns" + "?&slug=" + slug);
    let towns = response.data["hydra:member"];
    let town = towns[0]; // Sélectionnez le premier article du tableau
    response = await axios.get("towns/" + town.id);
    commit("setTown", response.data);
  },

  async CreateTown({ dispatch }, town) {
    await axios.post("towns", town);
    return await dispatch("GetTowns");
  },

  async UpdateTown({ dispatch }, data) {
    await axios.put("towns/" + data.id, data);
    return await dispatch("GetTowns");
  },

  async DeleteTown({ dispatch }, townId) {
    await axios.delete("towns/" + townId);
    return await dispatch("GetTowns");
  },
};

const mutations = {
  setTowns(state, towns) {
    state.towns = towns;
  },
  setTown(state, town) {
    state.town = town;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
