<template>
  <div class="container my-5">
    <section class="article-section">
      <!-- Grid row -->
      <div class="container-fluid">
        <!-- Grid column -->
        <div
          class="card-group col-12 mb-lg-0 mb-4"
          v-for="post in posts"
          :key="post.id"
        >
          <!-- Card -->
          <div class="card hoverable mb-5 mt-2" @click="showPost(post.id)">
            <div class="container-fluid p-0 header">
              <div class="header__img col-12 col-lg-6">
                <img
                  :src="
                    'https://api-blog.institut-taxi.fr' + post.img.contentUrl
                  "
                  alt="Sample image"
                  class="img-fluid"
                />
              </div>
              <div class="header__info container-fluid col-12 col-lg-5">
                <div class="title-head">
                  <h2 class="text-center">
                    {{ post.title }}
                  </h2>
                  <div class="hr"></div>
                </div>
                <div>
                  <p class="category text-uppercase">
                    {{ post.category.name }}
                  </p>
                </div>
                <div class="tags my-2">
                  <div class="tag" v-for="tag in post.tags" :key="tag.id">
                    <strong>{{ tag.label }}</strong>
                  </div>
                </div>
                <div class="card-date">
                  <div v-if="post.updatedAt !== '' && post.updatedAt !== null">
                    <p class="date">
                      modifié le: <br />
                      <time :datetime="post.updatedAt">{{
                        formatDate(post.updatedAt)
                      }}</time>
                    </p>
                  </div>
                  <div v-else>
                    <p>
                      publié le: <br />
                      <time :datetime="post.publishedAt">{{
                        formatDate(post.publishedAt)
                      }}</time>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Card -->
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
      <!-- Pagination -->
      <nav aria-label="Page-nav my-5">
        <ul class="pagination justify-content-center mt-5">
          <li
            class="page-item"
            :class="{ disabled: !Hydraview || !Hydraview['hydra:previous'] }"
          >
            <a class="page-link" @click="loadPage(Hydraview['hydra:previous'])"
              >&laquo;</a
            >
          </li>
          <li
            class="page-item"
            v-for="page in getDisplayedPageNumbers()"
            :key="page"
            :id="page"
            :class="{ active: isCurrentPage(page) }"
            @click="setActivePage(page)"
          >
            <a class="page-link">{{ page }}</a>
          </li>

          <li
            class="page-item"
            :class="{ disabled: !Hydraview || !Hydraview['hydra:next'] }"
          >
            <a class="page-link" @click="loadPage(Hydraview['hydra:next'])"
              >&raquo;</a
            >
          </li>
        </ul>
      </nav>
    </section>

    <!--Section: Content-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
export default {
  name: "ArticleSelect",
  props: {
    posts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activePage: 1, // Page active par défaut
    };
  },
  computed: {
    formattedDate() {
      return formatDate(this.post.updatedAt);
    },
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
      Hydraview: "StateHydraview",
    }),
  },

  methods: {
    ...mapActions(["GetPosts", "GetPost"]),
    async showPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "article",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    getDisplayedPageNumbers() {
      const currentPage = this.activePage;
      const totalPages = this.getPageNumbers().length;
      const maxDisplayedPages = 5; // Nombre maximal de pages à afficher entre les boutons "Next" et "Previous"

      let startPage, endPage;
      if (totalPages <= maxDisplayedPages) {
        // Si le nombre total de pages est inférieur ou égal à maxDisplayedPages, afficher toutes les pages
        startPage = 1;
        endPage = totalPages;
      } else if (currentPage <= Math.floor(maxDisplayedPages / 2)) {
        // Si la page active est proche du début, afficher les premières maxDisplayedPages pages
        startPage = 1;
        endPage = maxDisplayedPages;
      } else if (
        currentPage >=
        totalPages - Math.floor(maxDisplayedPages / 2)
      ) {
        // Si la page active est proche de la fin, afficher les dernières maxDisplayedPages pages
        startPage = totalPages - maxDisplayedPages + 1;
        endPage = totalPages;
      } else {
        // Sinon, afficher les pages autour de la page active
        startPage = currentPage - Math.floor(maxDisplayedPages / 2);
        endPage = currentPage + Math.floor(maxDisplayedPages / 2);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      );
    },

    loadPage(url) {
      const page = url.charAt(url.length - 1);
      const pageNum = parseInt(page);
      this.setActivePage(pageNum);
      this.getDisplayedPageNumbers();
    },
    loadNumPage(num) {
      const url =
        "articles?order%5BpublishedAt%5D=desc&isPublished=true&page=" + num;
      this.GetPosts(url);
    },
    getPageNumbers() {
      const lastPageUrl = this.Hydraview ? this.Hydraview["hydra:last"] : null;
      if (!lastPageUrl) {
        return [];
      }
      const urlParams = new URLSearchParams(lastPageUrl.split("?")[1]);
      const lastPageParam = urlParams.get("page");
      const totalPages = lastPageParam ? parseInt(lastPageParam) : 1;
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
    getCurrentPageFromUrl() {
      const currentRoute = this.$route;
      const queryParams = new URLSearchParams(currentRoute.query);
      const currentPage = queryParams.get("page");
      return currentPage ? parseInt(currentPage) : 1;
    },
    setActivePage(page) {
      this.activePage = page;
      this.loadNumPage(page);
    },
    isCurrentPage(page) {
      return this.activePage === page;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-section {
  margin-bottom: 200px;
}
.label-card {
  font-size: 0.6em;
  display: flex;
  justify-content: space-between;
  color: $color-text-grey;
}
p {
  margin-bottom: 0.3px;
}
.card {
  border-radius: 1px;
  border: 0.1px inset grey;
  margin-bottom: 30px;
  background-color: $color-primary;
  @include hover-effect-grown;
  cursor: pointer;
  & .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    & .title-head {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & h2 {
        font-size: 1.3em;
        margin-top: 30px;
        color: $color-text-light;
        @media screen and (min-width: 992px) {
          font-size: 2em;
        }
      }
    }
    & .category {
      margin-bottom: 0px;
      color: $color-secondary;
    }
    & .tags {
      font-size: 0.7em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .tag {
        display: block;
        margin-right: 4px;
        margin-bottom: 3px;
        padding: 0.5px 7px;
        border: 0.5px $color-text-light outset;
        background-color: $color-primary;
        color: $color-text-light;
      }
    }
  }
  .hr {
    width: 80%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto;
  }
}
.pagination li:hover {
  cursor: pointer;
}
.active .page-link {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
}
.card-date {
  display: none;
  @media screen and (min-width: 992px) {
    display: initial;
  }
  & .date {
    margin-top: 8px;
    font-size: 1em;
  }
}
</style>
