<template>
  <section class="underRow">
    <div class="header">
      <p>
        <strong>Centre de Formation Agréé</strong><br />
        <span class="litle"><strong>formation vtc</strong> et taxi</span>
      </p>
      <h2>l'Institut Taxi</h2>
      <p>
        Situé à <strong>Gennevilliers</strong>, dans les
        <strong>Hauts-de-Seine (92)</strong>, en <strong>Île-de-France</strong>.
        L'Institut Taxi vous propose ses
        <strong>formations vtc et taxi</strong> et vous aide à préparés
        l'<strong>examen</strong> <strong>t3p</strong> nécéssaire à l'obtention
        de votre <strong>carte professionnelle vtc</strong> et de votre futur
        <strong>carte professionnelle taxi</strong>.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "UnderRow",
};
</script>

<style lang="scss" scoped>
.underRow {
  font-size: 13px;
  padding: 5px;
  background-color: $color-primary;
  @media screen and (min-width: 992px) {
    padding: 10px 50px;
  }
  & h2:first-of-type {
    font-weight: 200;
    color: $color-secondary;
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
    }
  }
  & .header {
    text-align: center;
    line-height: 0.8;
    margin-bottom: 50px;
    margin-top: 30px;
    padding: 15px;
    @media screen and (min-width: 992px) {
    }
    & .litle {
      font-size: 0.8em;
    }
    & p {
      margin: 0 auto;
      line-height: 2;
      color: $color-text-light;
      text-align: center;
      @media screen and (min-width: 992px) {
        width: 90%;
        font-size: 1.5em;
        line-height: normal;
      }
    }
    & p:first-child {
      line-height: 20px;
      margin-bottom: 10px;
      & span {
        font-family: "quantum";
        font-size: 0.9em;
      }
    }
    & p:nth-last-child(n) {
      margin-top: 20px;
    }
  }
}

.cyan {
  border-top: 3px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 3px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 3px solid $color-primary;
}
.yellow {
  border-top: 3px solid hsl(59, 91%, 65%);
}

@media (min-width: 992px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-down {
    position: relative;
    top: 150px;
  }
  .box {
    width: 30%;
  }
}
</style>
