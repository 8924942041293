<template>
  <div class="modal-confirm">
    <div class="container">
      <div class="row">
        <p class="text-center title">{{ title }}</p>
      </div>
      <div class="row">
        <p class="text-center">{{ message }}</p>
      </div>
      <div class="row text-end">
        <button name="button" @click="closeModalConfirm">{{ btnExit }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfirmModal",
  props: {
    title: String,
    message: String,
    btnExit: String,
  },
  methods: {
    closeModalConfirm() {
      this.$emit("closeModalConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  z-index: 500;
  //background-color: $color-darker;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 40px 20px 20px;
  border: 0.5px outset $color-primary;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  & .title {
    color: $color-text-light;
    font-size: 1.2em;
    font-weight: 800;
    margin-bottom: 10px;
  }
  & p {
    color: $color-secondary;
    font-weight: 550;
    margin-bottom: 30px;
  }
  & button {
    color: $color-secondary;
    background-color: $color-primary;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 10px;
    margin: 20px auto;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      color: $color-primary;
      background-color: $color-secondary;
    }
  }
}
</style>
