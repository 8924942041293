<template>
  <div>
    <!-- Votre contenu HTML ici ******* eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg1MDc0MDA1LCJqdGkiOiI5NGIyNDU5Mi1kNmRiLTRjODYtOGU1ZS03ZjQ2ZDE1NzQxZDgiLCJ1c2VyX3V1aWQiOiJiNGFmZTlmZi0zZjYyLTQ3NjAtOTk0ZS1kOGJlZjMyNGFiM2IifQ.8YQu46Z9JSnJKFdZjgszx2uvkqHVsjYKhYyr-O3rZgeQjyB9cjyEe3Uej_DSJJoWuQmDde8dEbdDgSzm42pNkw-->
    <!--<div
      class="calendly-inline-widget"
      ref="calendlyWidget"
      data-url="https://calendly.com/institut-taxi-fr/formations-taxi-vtc?hide_event_type_details=1&hide_gdpr_banner=1&background_color=feb914&text_color=000000&primary_color=002a54"
      style="height: 600px"
    ></div>-->

    <!-- Calendly inline widget begin -->
    <div
      class="calendly-inline-widget"
      ref="calendlyWidget"
      data-url="https://calendly.com/d/2m7-syt-4n6/30min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=feb914&text_color=000000&primary_color=002a54"
      style="height: 600px"
    ></div>
    <!-- Calendly inline widget end -->
  </div>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  },
};
</script>
