<template>
  <section class="update-section container">
    <p class="title">Villes SEO cible</p>

    <div class="list">
      <div v-for="town in Towns" :key="town.id" class="px-5">
        <input
          v-model="selectedTown"
          @input="showTown(town.id)"
          type="radio"
          :value="town"
          name="radioTown"
          :id="town.id"
          class="input-hidden"
        />
        <label :for="town.id">
          <p>{{ town.town }} ({{ town.id }})</p>
          <p class="muted">{{ town.slug }}</p>
        </label>
      </div>
    </div>
    <div class="text-center">
      <div class="container">
        <p class="text-muted mb-0">Sélection</p>
      </div>
      <div class="container">
        <input
          type="text"
          v-model="form.name"
          :placeholder="selectedTown.town"
        />
      </div>
      <div class="d-flex justify-content-center my-5 pt-3">
        <button @click="updateTown(selectedTown.id)" class="btn btn-valid">
          Modifier
        </button>
        <button
          @click="deleteTown(selectedTown.id)"
          class="btn btn-delete ms-1"
        >
          Supprimer
        </button>
      </div>
    </div>
    <hr />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "mala-towns",
  components: {},
  data() {
    return {
      selectedTown: "",
      form: {
        town: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      Towns: "StateTowns",
      Town: "StateTown",
      User: "StateUser",
    }),
  },
  methods: {
    ...mapActions(["GetTown", "UpDateTown", "DeleteTown"]),
    async showTown(id) {
      try {
        await this.GetTown(id);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async updateTown(townId) {
      try {
        this.form.id = townId;
        //this.form.name = this.$ref.inputCat;
        await this.UpDateTown(this.form);
        this.form.name = "";
      } catch (error) {
        this.showError = true;
      }
    },
    async deleteTown(townId) {
      try {
        await this.DeleteTown(townId);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.update-section {
  padding: 50px 0px 200px 0px;
  & .title {
    font-size: 1.8em;
    text-align: center;
    margin: 50px 0px;
  }
  & .list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    width: 90vw;
    overflow: scroll;
    padding: 10px 20px;
    background-color: $color-darker;
    border-radius: 10px;
    border: 1px outset $color-darker;
    margin: 10px auto 40px auto;
    & .input-hidden {
      position: absolute;
      left: -9999px;
    }

    & input[type="radio"]:checked + label > p {
      border: 0.1px outset $color-primary;
      box-shadow: 0 0 3px 3px $color-primary;
    }

    /* Stuff after this is only to make things more pretty */
    & input[type="radio"] + label > p {
      border: 1px outset #444;
      transition: 500ms all;
    }
    & label {
      & p {
        padding: 5px 10px;
        border: 0.1px inset $color-darker;
        border-radius: 10px;
        font-size: 1em;
        color: $color-text;
      }
    }
  }
}
.btn-valid {
  width: fit-content;
  background-color: green;
  color: $color-text-light;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    scale: 96%;
  }
}
.btn-delete {
  width: fit-content;
  background-color: red;
  color: $color-text-light;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    scale: 96%;
  }
}
</style>
