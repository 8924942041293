<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Institut-Taxi` : `Institut-Taxi`
    }}</template>
  </metainfo>
  <div class="canva">
    <NavBase />
    <!--<NavBlog />-->
    <router-view />
    <LegalBar />
    <FooterBase />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBase from "@/components/NavBase.vue";
import LegalBar from "@/components/plugins/LegalBar.vue";
//import NavBlog from "@/components/NavBlog.vue";
import FooterBase from "@/components/FooterBase.vue";
import { useMeta } from "vue-meta";

export default {
  components: { NavBase, FooterBase, LegalBar },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "fr" },
      description: [
        {
          content:
            "Mettez toutes les chances de votre côté avec l'Institut Taxi. Formations pour devenir taxi ou VTC, stages de récupération de points T3P et services de sensibilisation. Réussissez votre parcours professionnel et maintenez votre permis. Contactez-nous dès aujourd'hui !",
        },
      ],
    });
  },
};
</script>

<style lang="scss">
.canva {
  overflow: hidden;
}
</style>
