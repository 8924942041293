<template>
  <div class="modal-confirm">
    <div class="container">
      <div class="row text-center">
        <p class="title">Supprimer l'article</p>
      </div>
      <div class="row text-center">
        <p>
          Vous êtes sur le point de supprimer cette article, l'action est
          irréversible.<br />Voulez-vous continués ?
        </p>
      </div>
      <div class="row text-end">
        {{ idPost }}
        <button @click="deletePost">supprimer</button>
        <button @click="closeModalDeletePost">quitter</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ModalDeletePost",
  props: {
    idPost: Number,
  },
  methods: {
    ...mapActions(["DeletePost"]),
    closeModalDeletePost() {
      this.$emit("closeModalDeletePost");
    },
    async deletePost() {
      try {
        const postId = this.idPost;
        await this.DeletePost(postId);
        this.$emit("deletePost");
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  //background-color: $color-darker;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 40px 20px 20px;
  border: 0.5px outset $color-primary;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  & .title {
    color: $color-primary;
    font-size: 1.2em;
    font-weight: 800;
    margin-bottom: 10px;
  }
  & p {
    color: $color-secondary;
    font-weight: 550;
    margin-bottom: 30px;
  }
  & button {
    color: $color-secondary;
    background-color: $color-primary;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 10px;
    margin: 20px auto;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      color: $color-primary;
      background-color: $color-secondary;
    }
  }
}
</style>
