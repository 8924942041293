import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import HomeView from "@/views/HomeView.vue";
import ServicesView from "@/views/ServicesView.vue";
import TaxiInitiale from "@/views/formations/TaxiInitiale.vue";
import TaxiContinue from "@/views/formations/TaxiContinue.vue";
import TaxiPratiqueSeule from "@/views/formations/TaxiPratiqueSeule.vue";
import VtcInitiale from "@/views/formations/VtcInitiale.vue";
import VtcContinue from "@/views/formations/VtcContinue.vue";
import VtcPratiqueSeule from "@/views/formations/VtcPratiqueSeule.vue";
//import RecuperationPoints from "@/views/formations/RecuperationPoints.vue";
import MobiliteDepartement from "@/views/formations/MobiliteDepartement.vue";
import PasserelleTaxiVtc from "@/views/formations/PasserelleTaxiVtc.vue";
import PasserelleVtcTaxi from "@/views/formations/PasserelleVtcTaxi.vue";
import BlogView from "@/views/BlogView.vue";
import ContactView from "@/views/ContactView.vue";
import MentionLegal from "@/views/MentionLegal.vue";
//import RegisterView from "@/views/RegisterView.vue";
import LoginView from "@/views/LoginView.vue";
import PostView from "@/views/PostView.vue";
import TownView from "@/views/TownView.vue";
import CreateTownView from "@/views/CreateTownView.vue";
import CreationTownView from "@/views/CreationTownView.vue";
import ProfileView from "@/views/ProfileView.vue";
import EditPostView from "@/views/EditPostView.vue";
import CreatePostView from "@/views/CreatePostView.vue";
import DashBoard from "@/views/malazone/DashBoard.vue";
import MalaTowns from "@/views/malazone/MalaTowns.vue";
import MalaUsers from "@/views/malazone/MalaUsers.vue";
import MalaCats from "@/views/malazone/MalaCats.vue";
import MalaTags from "@/views/malazone/MalaTags.vue";
import MalaPosts from "@/views/malazone/MalaPosts.vue";
import MalaMedias from "@/views/malazone/MalaMedias.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/formations-taxi-vtc",
    name: "services",
    component: ServicesView,
  },
  {
    path: "/formation-taxi-initiale",
    name: "TaxiInitiale",
    component: TaxiInitiale,
  },
  {
    path: "/formation-taxi-continue",
    name: "TaxiContinue",
    component: TaxiContinue,
  },
  {
    path: "/formation-taxi-pratique-seule",
    name: "TaxiPratiqueSeule",
    component: TaxiPratiqueSeule,
  },
  {
    path: "/formation-vtc-initiale",
    name: "VtcInitiale",
    component: VtcInitiale,
  },
  {
    path: "/formation-vtc-continue",
    name: "VtcContinue",
    component: VtcContinue,
  },
  {
    path: "/formation-vtc-pratique-seule",
    name: "VtcPratiqueSeule",
    component: VtcPratiqueSeule,
  },
  /*{
    path: "/stage-recuperation-point-permis-de-conduire",
    name: "RecuperationPoints",
    component: RecuperationPoints,
  },*/
  {
    path: "/formation-mobilite-taxi-92",
    name: "MobiliteDepartement",
    component: MobiliteDepartement,
  },
  {
    path: "/formation-mobilite-passerelle-taxi-vers-vtc",
    name: "PasserelleTaxiVtc",
    component: PasserelleTaxiVtc,
  },
  {
    path: "/formation-mobilite-passerelle-vtc-vers-taxi",
    name: "PasserelleVtcTaxi",
    component: PasserelleVtcTaxi,
  },

  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/mentions",
    name: "mentions",
    component: MentionLegal,
  },

  {
    path: "/update/:slug",
    name: "edit",
    component: EditPostView,
  },
  {
    path: "/new-article",
    name: "create",
    component: CreatePostView,
  },
  {
    path: "/new-town/",
    name: "UploadTown",
    component: CreateTownView,
  },
  {
    path: "/article/:slug",
    name: "article",
    component: PostView,
  },
  {
    path: "/formation-vtc/:slug",
    name: "formation-vtc",
    component: TownView,
  },
  {
    path: "/devenir-vtc/:town/:id",
    name: "CreationTownView",
    component: CreationTownView,
  },
  /*{
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: { guest: true },
  },*/
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/profile/:pseudo",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/ittizone",
    name: "malazone",
    component: DashBoard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/users",
    name: "mala-users",
    component: MalaUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/villes",
    name: "mala-towns",
    component: MalaTowns,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/posts",
    name: "mala-posts",
    component: MalaPosts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/categories",
    name: "mala-cats",
    component: MalaCats,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/tags",
    name: "mala-tags",
    component: MalaTags,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/medias",
    name: "mala-medias",
    component: MalaMedias,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  /*scrollBehavior() {
    return { x: 0, y: 0 };
  },*/
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/home");
      return;
    }
    next();
  } else {
    next();
  }
});
export default router;
