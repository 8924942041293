<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('carte-pro-vtc-taxi')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation passerelle taxi vers vtc" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/passerelle-taxi-vtc.webp"
                          alt="inside-car-man-driving"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">
                          PASSERELLE<br />
                          taxi vers vtc
                        </h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />600€</p>
                            <!-- Twitter -->
                            <p>
                              <span>Durée</span><br />5 jours / disponibilités à
                              convenir
                            </p>
                            <!-- Google+ -->
                            <p>
                              <span>Voiture de prêt</span><br />pour l'examen
                            </p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Transition Taxi vers VTC</h2>
                      <p>
                        Notre
                        <strong>formation passerelle taxi vers VTC</strong> vous
                        offre une <strong>transition</strong> fluide et efficace
                        pour élargir vos opportunités professionnelles. Avec
                        cette formation spécialisée, vous pourrez faire évoluer
                        votre carrière en
                        <strong
                          >passant du métier de chauffeur de taxi à celui de
                          chauffeur VTC</strong
                        >. Nous vous proposons un programme complet qui vous
                        permettra d'acquérir les compétences et les
                        connaissances nécessaires pour réussir dans votre
                        nouvelle activité.
                      </p>
                      <p>
                        Avec une durée de 5 jours de formation intensive, notre
                        programme vous permettra de maîtriser les aspects
                        spécifiques du <strong>métier de chauffeur VTC</strong>.
                        Vous apprendrez les règles et les réglementations
                        propres à l'<strong>activité VTC</strong>, les
                        techniques de relation client, la gestion des
                        réservations et des itinéraires, ainsi que les bonnes
                        pratiques commerciales pour développer votre activité.
                      </p>
                      <p>
                        Notre
                        <strong>formation passerelle taxi vers VTC</strong> est
                        disponible à convenir selon vos disponibilités. Nous
                        nous efforçons de vous offrir des horaires flexibles
                        afin de répondre à vos besoins et à votre emploi du
                        temps. Vous pourrez ainsi suivre la
                        <strong>formation</strong> sans perturber vos activités
                        professionnelles actuelles.
                      </p>
                      <p>
                        Pendant la <strong>formation</strong>, un véhicule vous
                        sera mis à disposition pour les exercices pratiques et
                        les mises en situation. Vous pourrez ainsi vous
                        familiariser avec les caractéristiques spécifiques des
                        véhicules utilisés dans l'<strong>activité VTC</strong>
                        et vous entraîner dans des conditions réalistes.
                      </p>
                      <p>
                        Une fois la formation terminée, vous serez prêt à passer
                        l'<strong>examen de conversion taxi vers VTC</strong>.
                        Nous vous accompagnerons tout au long du processus et
                        vous fournirons les informations nécessaires pour vous
                        préparer efficacement. Notre objectif est de vous donner
                        toutes les clés pour réussir dans votre nouvelle
                        <strong>carrière en tant que chauffeur VTC</strong>.
                      </p>
                      <p>
                        N'hésitez pas à vous inscrire à notre
                        <strong>formation passerelle taxi vers VTC</strong> et à
                        saisir cette opportunité de développer votre activité
                        professionnelle. Avec nos connaissances et notre
                        soutien, vous serez prêt à faire le grand saut vers une
                        nouvelle étape passionnante de votre carrière.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "PasserelleTaxiVtc",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "Passez de Taxi à VTC",
      description: [
        {
          content:
            "Optimisez votre carrière en passant de taxi à VTC avec la Formation Passerelle de Taxi vers VTC. Développez de nouvelles compétences et profitez de nouvelles opportunités dans le domaine du transport de personnes. Rejoignez notre programme de formation dès aujourd'hui !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-mobilite-passerelle-taxi-vers-vtc",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
