<template>
  <div class="services">
    <HeaderYellow
      anteTitle="Formations de qualité"
      title="Devenez Chauffeur de Taxi ou VTC"
      postTitle="Rejoignez notre Institut et lancez votre carrière dans le transport public particulier de personnes."
      :backgroundImage="require('@/assets/pictures/taxi-vtc.webp')"
    />
    <ServicesToggle />
  </div>
</template>

<script>
import HeaderYellow from "@/components/headers/HeaderYellow.vue";
import ServicesToggle from "@/components/services/ServicesToggle.vue";
import { useMeta } from "vue-meta";
export default {
  components: { HeaderYellow, ServicesToggle },
  setup() {
    useMeta({
      title: "Taxi & VTC",
      description: [
        {
          content:
            "Découvrez les services de l'Institut Taxi. Formations de qualité pour devenir taxi ou VTC, stages de récupération de points T3P et services de sensibilisation. Améliorez vos compétences, obtenez votre carte professionnelle et maintenez votre permis de conduire. Explorez nos services dès maintenant !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formations-taxi-vtc",
        },
      ],
    });
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      // Vérifier si la page est déjà en haut
      if (window.pageYOffset === 0) {
        return;
      }

      // Faire défiler vers le haut de la page
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
