<template>
  <div id="sticky-top" class="container-fluid sticky">
    <div
      class="row menu"
      :style="{
        background: changeColor ? 'rgb(255,250,250)' : 'rgb(255,250,250)',
      }"
    >
      <nav class="col navbar navbar-expand-lg navbar-light menu__nav">
        <router-link to="/home" class="navbar-brand brand-name">
          <img
            src="@/assets/brand/logo-square-transparent.webp"
            alt="logo web smart solution"
            width="70"
            class="mx-2"
          />
          <div class="naming">
            <p class="cunia">Institut Taxi</p>
            <div class="hr"></div>
            <p class="adam">formations taxi & vtc</p>
          </div>
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          aria-label="burger-button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarContent" class="collapse navbar-collapse mx-5">
          <ul
            class="navbar nav ms-auto flex-column flex-sm-row text-justify menu__ul sticky-top"
          >
            <li @click="closeMenu" class="nav-item">
              <router-link to="/home">Accueil</router-link>
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li @click="closeMenu" class="nav-item">
              <router-link to="/formations-taxi-vtc"
                >Nos formations</router-link
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li @click="closeMenu" class="nav-item">
              <router-link to="/contact">Contact</router-link>
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li @click="closeMenu" class="nav-item">
              <router-link to="/blog">Actualités</router-link>
              <div id="arrow_up" class="arrow_up"></div>
            </li>
          </ul>
          <div class="text-center">
            <BtnCallNav />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import BtnCallNav from "@/components/partials/BtnCallNav.vue";

export default {
  name: "NavBase",
  components: { BtnCallNav },
  data() {
    return {
      changeColor: true,
      scrollPosition: null,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 100) {
        this.changeColor = false;
      } else {
        this.changeColor = true;
      }
    },
    closeMenu() {
      // fermer le menu en utilisant le sélecteur Bootstrap
      const toggle = document.querySelector(".navbar-toggler");
      const collapse = document.querySelector("#navbarContent");
      toggle.classList.remove("show");
      collapse.classList.remove("show");

      // déplacer la fenêtre du navigateur vers le haut de la page
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
}
.menu {
  & .navbar-toggler {
    margin-right: 10px;
  }
  & .brand-name {
    display: flex;
    justify-content: center;
    align-content: center;
    @media screen and (min-width: 992px) {
      margin: 5px 50px;
    }
  }
  & .naming {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    & .hr {
      background-color: $color-text-grey;
      width: 140px;
      height: 1px;
    }
    & p:first-of-type {
      font-size: 0.8em;
      text-align: center;
      margin-bottom: 0.2px;
    }
    & p:last-of-type {
      font-size: 0.5em;
      text-align: center;
    }
  }
  & ul {
    margin-right: 15px;
  }
  &__span {
    color: $color-text;
  }
  &__ul {
    padding: 5px 10px;
  }
  & .nav-item {
    font-size: 0.8em;
    margin: 0px;
    padding: 10px 0px;
    width: 100%;
    background-color: $color-primary;
    border: 0.2px $color-secondary outset;
    border-radius: 5px;
    text-align: center;
    @media screen and (min-width: 992px) {
      margin: 2px;
      width: fit-content;
      background-color: $background;
      border: none;
    }
  }
  &__nav {
    margin-bottom: 0px;
    & a:not(.brand-name) {
      padding: 0px 5px;
      color: $color-text-light;
      text-decoration: none;
      @media screen and (min-width: 992px) {
        color: $color-text;
      }
      &:hover {
        text-decoration: underline $color-secondary;
        text-underline-offset: 4px;
      }
      &.router-link-exact-active {
        color: $color-secondary;
        @media screen and (min-width: 992px) {
          color: $color-primary;
        }
      }
    }
  }
}

.phone {
  border: 1px outset $color-primary;
  border-radius: 9%;
  font-size: 0.6em;
  margin-left: 20px;
  padding: 3px;
  & a {
    color: $color-primary;
    & a:hover {
      color: $color-text;
    }
  }
}

/*@media screen and (max-width: 992px) {
  .s-100 {
    height: 100vw;
  }
}
.active {
  position: relative;
  width: 30px;
  height: 20px;
  left: 16%;
  top: -58px;
  background-color: $color-primary;
  transform: rotate(45deg);
}*/
</style>
