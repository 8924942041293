<template>
  <div class="container pt-5 my-5">
    <!--Section: Content-->
    <section class="mx-md-5 dark-grey-text">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <!-- Card -->
          <div class="card body-article">
            <button
              name="button"
              @click="circleBtn('carte-pro-vtc-taxi')"
              class="retourBtn"
            >
              <span>&#171;</span> Retour formations
            </button>

            <OrderLesson lesson="Formation passerelle vtc vers taxi" />
            <!-- Card image -->
            <div class="container mt-5">
              <!--Section: Content-->
              <section class="mx-md-5">
                <!-- Grid row -->
                <div class="row">
                  <!-- Grid column -->
                  <article class="col-md-12">
                    <!-- Card -->
                    <div class="card cadre-img">
                      <!-- Card image -->
                      <div class="">
                        <img
                          class="card-img-top"
                          src="@/assets/pictures/passerelle-vtc-taxi.webp"
                          alt="car-taxi"
                          loading="lazy"
                        />
                      </div>

                      <!-- Card content -->
                      <div class="card-body body-article__header text-center">
                        <!-- Title -->
                        <h1 class="font-weight-bold">
                          PASSERELLE<br />
                          vtc vers taxi
                        </h1>
                        <!-- Social shares -->
                        <div class="advantage-thumb">
                          <div class="advantage-thumb__line">
                            <!-- Facebook -->
                            <p><span>Prix</span><br />600€</p>
                            <!-- Twitter -->
                            <p>
                              <span>Durée</span><br />5 jours / disponibilités à
                              convenir
                            </p>
                            <!-- Google+ -->
                            <p>
                              <span>Voiture de prêt</span><br />pour l'examen
                            </p>
                          </div>
                        </div>
                        <!-- Social shares -->
                      </div>
                      <!-- Card content -->
                    </div>
                    <!-- Card -->

                    <!-- Excerpt -->
                    <div class="mt-5 body-article__content">
                      <h2>Passer du VTC au Taxi</h2>
                      <p>
                        Notre
                        <strong>formation passerelle VTC vers taxi</strong> vous
                        offre la possibilité de diversifier vos compétences et
                        de vous ouvrir de nouvelles perspectives dans le domaine
                        du <strong>transport de personnes</strong>. Avec cette
                        <strong>formation</strong> spécialisée, vous pourrez
                        passer du <strong>métier de chauffeur VTC</strong> à
                        celui de <strong>chauffeur de taxi</strong> et ainsi
                        élargir vos opportunités professionnelles. Nous vous
                        proposons un programme complet qui vous permettra
                        d'acquérir les connaissances et les compétences
                        spécifiques nécessaires pour réussir dans votre nouvelle
                        activité.
                      </p>

                      <p>
                        Avec une durée de <strong>formation</strong> adaptée,
                        notre programme vous permettra de vous familiariser avec
                        les particularités du
                        <strong>métier de chauffeur de taxi</strong>. Vous
                        apprendrez les réglementations locales et nationales en
                        vigueur, les spécificités de la gestion de l'<strong
                          >activité de taxi</strong
                        >, les itinéraires et les zones géographiques couvertes,
                        ainsi que les bonnes pratiques de relation client. Vous
                        serez ainsi prêt à offrir un service de qualité et à
                        répondre aux attentes des passagers.
                      </p>

                      <p>
                        Notre
                        <strong>formation passerelle VTC vers taxi</strong> est
                        conçue pour s'adapter à vos disponibilités. Nous
                        proposons des plages horaires flexibles afin de
                        faciliter votre apprentissage sans perturber vos
                        engagements professionnels actuels. Vous pourrez ainsi
                        suivre la <strong>formation</strong> à votre rythme et
                        selon vos contraintes personnelles.
                      </p>

                      <p>
                        Pendant la <strong>formation</strong>, nous mettons à
                        votre disposition des véhicules adaptés aux exigences du
                        <strong>métier de chauffeur de taxi</strong>. Vous
                        pourrez vous entraîner dans des conditions réalistes,
                        vous familiariser avec les équipements spécifiques et
                        vous perfectionner dans les techniques de conduite
                        propres à cette activité. Notre objectif est de vous
                        préparer au mieux pour réussir votre
                        <strong>conversion de VTC vers taxi</strong>.
                      </p>

                      <p>
                        Une fois la formation terminée, vous serez prêt à passer
                        l'<strong>examen de conversion VTC vers taxi</strong>.
                        Nous vous accompagnons tout au long du processus et vous
                        fournissons les informations nécessaires pour vous
                        préparer efficacement. Notre équipe pédagogique
                        expérimentée est là pour répondre à vos questions et
                        vous soutenir dans cette transition professionnelle.
                      </p>

                      <p>
                        Ne manquez pas cette opportunité de développer vos
                        compétences et d'élargir votre champ d'action en
                        <strong
                          >passant de chauffeur VTC à chauffeur de taxi</strong
                        >. Inscrivez-vous dès maintenant à notre
                        <strong>formation passerelle VTC vers taxi</strong> et
                        donnez une nouvelle impulsion à votre carrière dans le
                        <strong>secteur du transport de personnes</strong>.
                      </p>
                    </div>
                  </article>
                </div>
                <div class="hr"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="hrFooter"></div>
</template>

<script>
import OrderLesson from "@/components/plugins/OrderLesson.vue";
import { useMeta } from "vue-meta";
export default {
  name: "PasserelleVtcTaxi",
  components: { OrderLesson },
  setup() {
    useMeta({
      title: "Passez de VTC à Taxi",
      description: [
        {
          content:
            "Transformez votre parcours professionnel en passant de VTC à taxi avec la Formation Passerelle de VTC à Taxi. Acquérez les connaissances et les compétences nécessaires pour devenir chauffeur de taxi qualifié. Explorez de nouvelles perspectives dès maintenant !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/formation-mobilite-passerelle-vtc-vers-taxi",
        },
      ],
    });
  },
  data() {
    return {
      showButton: false,
      scrollHeight: 500, // Hauteur de défilement souhaitée
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    circleBtn(itemActif) {
      this.$router.push({
        path: "/formations-taxi-vtc",
        query: { itemActif: itemActif },
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    handleScroll() {
      /*if (window.pageYOffset > this.scrollHeight) {
        this.showButton = false; // Masquer le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = true; // Afficher le bouton sinon
      }*/
      if (window.pageYOffset > this.scrollHeight) {
        this.showButton = true; // Afficher le bouton lorsque la hauteur de défilement est atteinte
      } else {
        this.showButton = false; // Masquer le bouton sinon
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body-article {
  & strong {
    font-style: none !important;
  }
  position: relative;
  @include boxShadowLight;
  background-color: $color-primary;
  margin-top: 20px;
  & .retourBtn {
    @include black-gradient;
    color: $color-secondary;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    padding: 1px 5px;
    transition: 0.3s;
    width: fit-content;
    & span {
      font-size: 1.4em;
      color: $color-text-light;
    }
    &:hover {
      color: $color-text-light;
      & span {
        color: $color-secondary;
      }
    }
  }

  & .cadre-img {
    background-color: $color-secondary;
    & img {
      @media screen and (min-width: 992px) {
        max-height: 390px;
      }
    }
  }
  &__header {
    @include black-gradient;
    padding: 0px;
    & h1 {
      font-family: "quantum";
      color: $color-text-light;
      background-color: $color-secondary;
      padding: 7px;
    }
    & .advantage-thumb {
      padding: 5px 5px 8px;
      border-radius: 10px;
      @media screen and (min-width: 992px) {
        padding: 5px 5px 10px;
      }
      &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        @media screen and (min-width: 992px) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        & p {
          @include black-gradient;
          padding: 5px 8px;
          border: 0.4px solid $color-secondary;
          border-radius: 5px;
          color: $color-text-light;
          font-size: 0.9em;
          font-style: normal !important;
          text-align: center;
          height: 100%;
          margin: 5px 20px;
          & span {
            color: $color-secondary;
          }
        }
      }
    }
  }
  &__content {
    color: $color-text-light;
    & h2 {
      color: $color-secondary;
      margin: 70px 0px 20px;
      text-align: center;
      @media screen and (min-width: 992px) {
        text-align: left;
      }
    }
    & h3,
    h4,
    h5 {
      color: $color-primary;
    }
    & p {
      font-size: 1em;
    }
  }
  & .hr {
    width: 100%;
    height: 1px;
    background-color: $color-secondary;
    margin: 10px auto 50px;
  }
}
h3,
h4,
h5 {
  color: $color-primary;
  text-align: center;
  margin-bottom: 15px;
}
.hrFooter {
  width: 70%;
  height: 1px;
  background-color: $color-text-grey;
  margin: 10px auto 100px;
}
</style>
