<template>
  <div class="container-fluid brand-footer">
    <div class="brand-name">
      <img
        src="@/assets/brand/logo-square-transparent.webp"
        alt="logo-institut-taxi"
        width="200"
        class="mx-2"
        loading="lazy"
      />
      <div class="naming">
        <p class="cunia">Institut Taxi</p>
        <div class="hr"></div>
        <p class="adam"><strong>formations taxi & vtc</strong></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnFooter",
};
</script>

<style lang="scss" scoped>
.brand-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 30px 0px;
  & .naming {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    & .hr {
      background-color: $color-text-grey;
      width: 100%;
      height: 1px;
      margin-bottom: 3px;
    }
    & p:first-of-type {
      font-size: 1.3em;
      text-align: center;
      margin-bottom: 0.2px;
    }
    & p:last-of-type {
      font-size: 1em;
      text-align: center;
    }
  }
}
</style>
